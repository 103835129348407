import { TInformationBlock, TLink, TResImage } from "@/shared/model/types";
import { useEffect } from "react";
import { useArticles } from "../hooks/use-articles";
import { formatDate, formatTime } from "@/shared/lib";
import { InformationBlock } from "./information-block";
import { ShareButton } from "@/shared/ui/share-button";

type TArticleData = {
  id: number;
  title: string;
  sub_title?: null;
  view_counter: number;
  publishedAt: string;
  preview_image: TResImage;
  blocks: TInformationBlock[];
  article_links: TLink[];
};

interface IProps {
  articleData: TArticleData;
}

export const ArticleDetails: React.FC<IProps> = ({ articleData }) => {
  const { incrementViewCounter } = useArticles();

  useEffect(() => {
    incrementViewCounter(articleData.id, articleData?.view_counter);
  }, []);

  return (
    <>
      <div className="flex items-end justify-between -mt-6">
        <p className="text-gray-400">
          Опубликовано: {formatDate(articleData.publishedAt)} в{" "}
          {formatTime(articleData.publishedAt.split("T")[1])}
        </p>
        <ShareButton />
      </div>
      <h2 className="mt-4 text-xl fb">{articleData.title}</h2>
      <p className="mt-2">{articleData?.sub_title}</p>
      <div className="flex flex-col gap-4 mt-4">
        {articleData.blocks.map((block) => (
          <div key={block.id}>
            <InformationBlock block={block} />
          </div>
        ))}
      </div>
      <div className="flex gap-2">
        {articleData?.article_links?.map((link) => (
          <a key={link.id} href={link.url} className="link" target="_blank">
            {link.title}
          </a>
        ))}
      </div>
    </>
  );
};
