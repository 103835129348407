import { Container } from "@/shared/ui";
import { Component, ErrorInfo, ReactNode } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true });
    if (import.meta.env.MODE !== "development") {
      console.error("Uncaught error:", error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError && import.meta.env.MODE !== "development") {
      return (
        <Container className="flex flex-col items-center justify-center flex-1 gap-2">
          <h2>Не удалось загрузить данную страницу.</h2>
          <p>
            Не волнуйтесь, мы уже получили отчет об ошибке и в скором времени
            данная страница снова заработает.
          </p>
          <svg
            width="3rem"
            height="3rem"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"></path>
            <path
              fill="currentColor"
              stroke="none"
              d="M8.625 11.625a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
            ></path>
            <path
              fill="currentColor"
              stroke="none"
              d="M15.375 11.625a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
            ></path>
            <path d="M15.9 14.25a4.51 4.51 0 0 1-7.8 0"></path>
          </svg>
        </Container>
      );
    }

    return this.props.children;
  }
}
