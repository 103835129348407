import { useEffect, useState } from "react";
import { eventTypes } from "../model/event-types";
import qs from "qs";

interface IProps {
  setFilterByTypes: (filterByTags: string) => void;
  className?: string;
  width?: string;
}

export const FilterByTypesDropdownMenu: React.FC<IProps> = ({
  setFilterByTypes,
  className = "",
  width = "auto",
}) => {
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

  useEffect(() => {
    if (selectedTypes.length === 0) {
      setFilterByTypes("");
    } else {
      setFilterByTypes(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTypes]);

  const filter = qs.stringify({
    filters: {
      $or: selectedTypes.map((type) => ({
        $or: [{ type }],
      })),
    },
  });

  function clearSelectedTags() {
    setSelectedTypes([]);
  }

  function handleToggleTag(type: string) {
    setSelectedTypes((prev) => {
      if (prev.includes(type)) {
        return prev.filter((item: string) => item !== type);
      }
      return [...prev, type];
    });
  }

  return (
    <details className={`dropdown ${className}`}>
      <summary className={`btn btn-sm w-${width}`}>Фильтры</summary>
      <div
        className={`p-2 w-${width} shadow menu dropdown-content z-[1] bg-base-100 rounded-box max-h-72 overflow-y-auto flex-nowrap`}
      >
        <label className="space-x-2 cursor-pointer label">
          <span className="label-text">Все</span>
          <input
            type="checkbox"
            onChange={clearSelectedTags}
            checked={selectedTypes.length === 0}
            className="checkbox checkbox-primary"
          />
        </label>
        {eventTypes.map((type) => (
          <label className="space-x-2 cursor-pointer label" key={type}>
            <span className="label-text">{type}</span>
            <input
              onChange={() => handleToggleTag(type)}
              type="checkbox"
              checked={selectedTypes?.some(
                (selectedType) => selectedType === type
              )}
              className="checkbox checkbox-primary"
            />
          </label>
        ))}
      </div>
    </details>
  );
};
