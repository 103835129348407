import { Link } from "react-router-dom";
import { FavoriteButton } from "@/features/favorite-place";
import { ToggleTripPlacesButton } from "@/features/trip-place";
import { LockUnlock } from "@/entities/places";
import { TPlace } from "@/shared/model/types";
import { Price } from "@/shared/ui";
import { useAppSelector } from "@/shared/model/hooks";
import { selectIsAuthorized } from "@/entities/session";
import { ModalLogin } from "@/features/auth";
import { useWindowDimensions } from "@/shared/hooks";

export const PopupContent: React.FC<{ place: TPlace }> = ({ place }) => {
  const isAuth = useAppSelector(selectIsAuthorized);
  const btnStyle = `btn btn-sm text-nowrap btn-primary text-sm w-full`;
  const { width: windowWith } = useWindowDimensions();
  const isDesktop = windowWith >= 1024;

  function getActionButton(): JSX.Element {
    if (!isAuth && !isDesktop)
      return (
        <>
          <Link to={"/auth/login"}>
            <button className={btnStyle}>Добавить в избранное</button>
          </Link>
          <Link to={"/auth/login"}>
            <button className={btnStyle}>Добавить в маршрут</button>
          </Link>
        </>
      );

    if (!isAuth && isDesktop)
      return (
        <>
          <ModalLogin subtitle="Авторизуйтесь, чтобы сохранить место в избранных и никогда не терять его из виду">
            <button className={btnStyle}>Добавить в избранное</button>
          </ModalLogin>
          <ModalLogin subtitle="Авторизуйтесь, чтобы добавить место в свой уникальный маршрут">
            <button className={btnStyle}>Добавить в маршрут</button>
          </ModalLogin>
        </>
      );

    return (
      <>
        <FavoriteButton placeId={place.id} isButtonWithText size="sm" />
        <ToggleTripPlacesButton placeId={place.id} size="sm" />
      </>
    );
  }

  return (
    <div className="flex flex-col ">
      <p className="m-0 text-lg fb">{place.title}</p>
      <LockUnlock
        working_hours={place.working_hours}
        working_hours_weekends={place.working_hours_weekends}
        isOnlyText
      />
      <div className="flex m-0">
        <p>Средний чек:</p>
        <Price price={place.average_price} />
      </div>
      <Link to={"/places/" + place.id} className="link">
        Перейти на страницу
      </Link>
      <div className="flex flex-col gap-2 mt-4">{getActionButton()}</div>
    </div>
  );
};
