import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { TImage, TResImage } from "@/shared/model/types";
import { BreadCrumbs, Loading } from "@/shared/ui";
import { EventDetails, useGetEventByIdQuery } from "@/entities/events";
import { AlreadyTicket } from "./already-ticket";
import { JoinEvent } from "@/features/join-event/ui/join-event";
import { selectIsOpenFromMobileApp } from "@/entities/session";
import { useAppSelector } from "@/shared/model/hooks";
import {
  filterSchedules,
  findMinByField,
  isDateGreaterThanNow,
} from "@/shared/lib";

export const EventInfo: React.FC = () => {
  const { id } = useParams();
  const isOpenFromMobileApp = useAppSelector(selectIsOpenFromMobileApp);

  const { eventData, isLoading, images, isFetching } = useGetEventByIdQuery(
    { id: Number(id) },
    {
      selectFromResult: (res) => ({
        eventData: res?.data?.data,
        images: res?.data?.data?.images?.map(
          (image: TResImage): TImage => ({
            id: image.id,
            url:
              image?.formats?.medium?.url ||
              image?.formats?.small?.url ||
              image?.formats?.thumbnail?.url,
          })
        ),
        ...res,
      }),
      skip: !id,
    }
  );

  const minPrice = useMemo(() => {
    if (eventData && eventData.schedules) {
      const filteredSchedules = filterSchedules(eventData.schedules);
      return findMinByField(filteredSchedules, "price");
    }
  }, [eventData]);

  if (isLoading || isFetching)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading size="lg" />
      </div>
    );

  const isFreeEvent = minPrice === 0;

  function getAction() {
    const isAlreadyTookPlace = !isDateGreaterThanNow(eventData.date_delition);
    const isPaymentEnabled = eventData.is_payment_enabled;

    if (isAlreadyTookPlace)
      return (
        <button disabled className="btn">
          Мероприятие уже прошло
        </button>
      );

    if (eventData.website_link)
      return (
        <a
          target="_blank"
          href={eventData.website_link.url}
          className="text-lg btn btn-primary font-[400] w-full"
        >
          {eventData.website_link.title}
        </a>
      );

    if (!isPaymentEnabled)
      return (
        <button disabled className="btn">
          Оплата билета отключена
        </button>
      );

    return (
      <JoinEvent
        eventData={eventData}
        btnText={
          minPrice === 0
            ? "Получить билет"
            : "Стоимость: " + minPrice + " руб"
        }
      />
    );
  }
  return (
    <div>
      {!isOpenFromMobileApp && <BreadCrumbs />}
      {eventData && (
        <EventDetails
          images={images}
          eventData={eventData}
          AdditionalDetails={
            <>
              <li>
                Стоимость: {isFreeEvent ? "Бесплатно" : minPrice + " руб."}
              </li>
              {eventData?.organization && (
                <li>
                  Организация:{" "}
                  <Link
                    className="link"
                    to={`/organizations/${eventData.organization.id}`}
                  >
                    {eventData.organization?.title}
                  </Link>
                </li>
              )}
            </>
          }
        >
          {!isOpenFromMobileApp && <AlreadyTicket eventId={eventData.id} />}
          {getAction()}
        </EventDetails>
      )}
    </div>
  );
};
