import { useGetAllPlacesQuery } from "@/entities/places";
import { TPlace } from "@/shared/model/types";

export const useInterestingPlaces = () => {
  const { places } = useGetAllPlacesQuery(
    {
      filter: `filters[$and][1][recommended][$eq]=true`,
      populate:
        "populate=preview_image, working_hours, working_hours_weekends, type",
      page: 1,
      pageSize: 3,
    },
    {
      selectFromResult: (res) => {
        return {
          places: res?.data?.data as TPlace[],
        };
      },
    }
  );
  return { places };
};
