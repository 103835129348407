import { useEffect, useState } from "react";
import {
  AfishaIcon,
  AirTicketIcon,
  HotelIcon,
  RestaurantsIcon,
  TrainTicketIcon,
  // ToursAndExcursionsIcon,
} from "@/shared/ui/icon";
import {
  AfishaSearch,
  AirTicketsSearch,
  HotelSearch,
  RailwayTicketsSearch,
  RestaurantsSearch,
} from "@/features/search-engines";

export const useSearchPanel = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth > 640);
  // TODO: перенести иконки и их импорты в shared
  // interface IconProps {
  //   isActive: boolean;
  // }
  interface ITabsList {
    ariaLabel: string;
    icon: React.FC;
    component: React.ReactNode;
  }
  const tabList: ITabsList[] = [
    {
      ariaLabel: "Афиша",
      icon: (props) => <AfishaIcon {...props} />,
      component: <AfishaSearch />,
    },
    {
      ariaLabel: "Рестораны",
      icon: (props) => <RestaurantsIcon {...props} />,
      component: <RestaurantsSearch />,
    },
    {
      ariaLabel: "Отели",
      icon: (props) => <HotelIcon {...props} />,
      component: <HotelSearch />,
    },

    {
      ariaLabel: "Авиабилеты",
      icon: (props) => <AirTicketIcon {...props} />,
      component: <AirTicketsSearch />,
    },
    {
      ariaLabel: "Ж/Д билеты",
      icon: (props) => <TrainTicketIcon {...props} />,
      component: <RailwayTicketsSearch />,
    },
    // {
    //   ariaLabel: "Туры и экскурсии",
    //   icon: (props) => <ToursAndExcursionsIcon {...props} />,
    //   component: <ToursAndExcursionsSearch />,
    // },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { selectedTab, isDesktop, setSelectedTab, tabList };
};
