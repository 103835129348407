import { baseApi } from "@/shared/api";
import {
  FAVORITE_PLACES_TAG,
  ROUTE_TAG,
  TICKETS_TAG,
  TRIP_TAG,
  USER_HOME_TAG,
  USER_TAG,
} from "@/shared/api/tags";

export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserInfo: build.query({
      query: () => ({
        url: `/users/me?populate=avatar`,
        method: "GET",
      }),
      providesTags: [USER_TAG],
    }),
    getUserTickets: build.query({
      query: () => ({
        url: `/users/me?populate[tickets][populate]=event.schedules&populate[tickets][populate]=seats`,
        method: "GET",
      }),
      providesTags: [TICKETS_TAG],
    }),
    getFavoritePlaces: build.query({
      query: () => ({
        url: `/users/me?populate[favorite_places][populate]=images, preview_image, type.icon, working_hours, working_hours_weekends, icon`,
        method: "GET",
      }),
      providesTags: [FAVORITE_PLACES_TAG],
    }),
    updateFavoritePlaces: build.mutation({
      query: ({
        id,
        favorite_places,
      }: {
        id: number;
        favorite_places: number[];
      }) => ({
        url: `/users/${id}`,
        method: "PUT",
        body: { favorite_places },
      }),
      invalidatesTags: [FAVORITE_PLACES_TAG],
    }),
    getTripPlaces: build.query({
      query: () => ({
        url: `/users/me?populate[trip_places][populate]=images, preview_image, type.icon, working_hours, working_hours_weekends, icon`,
        method: "GET",
      }),
      providesTags: [TRIP_TAG],
    }),
    getUserHome: build.query({
      query: () => ({
        url: `/users/me?populate[home][populate]=type.icon, working_hours, working_hours_weekends `,
        method: "GET",
      }),
      providesTags: [USER_HOME_TAG],
    }),
    updateTripPlaces: build.mutation({
      query: ({ id, trip_places }: { id: number; trip_places: number[] }) => ({
        url: `/users/${id}`,
        method: "PUT",
        body: { trip_places },
      }),
      invalidatesTags: [TRIP_TAG, ROUTE_TAG],
    }),
    updateUsername: build.mutation({
      query: ({ newUsername, id }: { newUsername: string; id: number }) => ({
        url: `/users/${id}`,
        method: "PUT",
        body: { username: newUsername },
      }),
      invalidatesTags: [USER_TAG],
    }),
    updateEmail: build.mutation({
      query: ({ newEmail, id }: { newEmail: string; id: number }) => ({
        url: `/users/${id}`,
        method: "PUT",
        body: { email: newEmail },
      }),
      invalidatesTags: [USER_TAG],
    }),
    updatePhone: build.mutation({
      query: ({ newPhone, id }: { newPhone: string; id: number }) => ({
        url: `/users/${id}`,
        method: "PUT",
        body: { phone: newPhone },
      }),
      invalidatesTags: [USER_TAG],
    }),
    updateHome: build.mutation({
      query: ({ placeId, id }: { placeId: number | null; id: number }) => ({
        url: `/users/${id}`,
        method: "PUT",
        body: { home: placeId },
      }),
      invalidatesTags: [USER_HOME_TAG],
    }),
    deleteMyAccount: build.mutation({
      query: ({ id }: { id: number }) => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [USER_TAG],
    }),
    updateAvatar: build.mutation({
      query: (formData) => ({
        url: `/upload`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [USER_TAG],
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useGetUserTicketsQuery,
  useGetFavoritePlacesQuery,
  useUpdateFavoritePlacesMutation,
  useUpdateUsernameMutation,
  useUpdateEmailMutation,
  useUpdatePhoneMutation,
  useUpdateAvatarMutation,
  useGetTripPlacesQuery,
  useUpdateTripPlacesMutation,
  useGetUserHomeQuery,
  useUpdateHomeMutation,
  useDeleteMyAccountMutation,
} = userApi;
