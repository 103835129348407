import { Container } from "@/shared/ui";
import { Link } from "react-router-dom";
import DiagonalArrow from "@/shared/assets/icons/diagonal-arrow.svg?react";
import { useInterestingPlaces } from "../hooks/use-interesting-places";
import ArticleCard from "@/shared/ui/article-card";
import { LockUnlock } from "@/entities/places";

const InterestingPlaces = () => {
  const { places } = useInterestingPlaces();
  return (
    <Container className="my-10">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold">Достопримечательности</h1>
        <Link to={"/places/list/dostoprimechatelnosti"}>
          <button className="btn hidden md:flex bg-white border border-primary font-sans">
            Смотреть больше
            <DiagonalArrow />
          </button>
        </Link>
      </div>
      <div className="flex gap-[5%] justify-start overflow-x-auto">
        {places &&
          places.map((item, index) => (
            <div className="w-full md:w-[30%] shrink-0" key={index}>
              <Link to={`/places/${item.id}`}>
                <ArticleCard imageSrc={item.preview_image.url} altText="Shoes">
                  <h2 className="card-title">{item.title}</h2>
                  <p className="text-gray-500 line-clamp-3">
                    {item.description}
                  </p>
                  <div className="mt-2">
                    <LockUnlock
                      working_hours={item?.working_hours}
                      working_hours_weekends={item?.working_hours_weekends}
                    />
                  </div>
                </ArticleCard>
              </Link>
            </div>
          ))}
      </div>
      <Link to={"/places/list/dostoprimechatelnosti"}>
        <button className="btn flex mx-auto md:hidden bg-white border border-primary font-sans mt-5">
          Смотреть больше
          <DiagonalArrow />
        </button>
      </Link>
    </Container>
  );
};
export default InterestingPlaces;
