import arnament1 from "@/shared/assets/arnament-1.png";
import arnament2 from "@/shared/assets/arnament-2.png";
import React from "react";

interface IProps {
  type?: 1 | 2 | 3;
}

const Type1: React.FC = () => {
  return (
    <div className="flex flex-col justify-between w-full h-full opacity-50">
      <div className="flex justify-center mt-[73vh]">
        <img src={arnament1} alt="arnament1" />
        <img src={arnament1} alt="arnament1" />
        <img src={arnament1} alt="arnament1" />
        <img src={arnament1} alt="arnament1" />
      </div>
      <div className="flex justify-center rotate-180 ">
        <img src={arnament1} alt="arnament1" />
        <img src={arnament1} alt="arnament1" />
        <img src={arnament1} alt="arnament1" />
        <img src={arnament1} alt="arnament1" />
      </div>
      <div className="flex justify-center opacity-10">
        <img src={arnament2} alt="arnament2" />
        <img src={arnament2} alt="arnament2" />
        <img src={arnament2} alt="arnament2" />
        <img src={arnament2} alt="arnament2" />
      </div>
    </div>
  );
};

const Type2: React.FC = () => {
  return (
    <div className="flex flex-col w-full h-full opacity-50">
      <div className="flex justify-center -mt-[15rem] xl:-mt-[25rem]">
        <img className="w-[50rem]" src={arnament1} alt="arnament1" />
        <img className="w-[50rem]" src={arnament1} alt="arnament1" />
      </div>
      <div className="flex justify-center mt-[5rem] opacity-10">
        <img className="w-[50rem]" src={arnament2} alt="arnament2" />
        <img className="w-[50rem]" src={arnament2} alt="arnament2" />
      </div>
    </div>
  );
};

const Type3: React.FC = () => {
  return (
    <div className="flex flex-col justify-center w-full h-full opacity-50">
      <div className="flex justify-center">
        <img src={arnament1} alt="arnament1" />
        <img src={arnament1} alt="arnament1" />
        <img src={arnament1} alt="arnament1" />
        <img src={arnament1} alt="arnament1" />
      </div>
    </div>
  );
};

export const Background: React.FC<IProps> = ({ type = 1 }) => {
  return (
    <div className="absolute flex-col w-full h-full overflow-hidden -z-10">
      {type === 1 && <Type1 />}
      {type === 2 && <Type2 />}
      {type === 3 && <Type3 />}
    </div>
  );
};
