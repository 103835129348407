import { MapContainer, TileLayer, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { LatLngExpression } from "leaflet";
import { createContext, useState } from "react";
import { useWindowDimensions } from "@/shared/hooks";

interface IProps {
  children: React.ReactNode;
}

function MapEvents({
  setZoom,
}: {
  setZoom: React.Dispatch<React.SetStateAction<number>>;
}) {
  const map = useMapEvents({
    zoomend() {
      setZoom(map.getZoom());
    },
  });

  return null;
}

export const MapZoomContext = createContext(14);

const maskCoordinates: [number, number][] = [
  [48.305, 41.616],
  [44.716, 47.758],
];

export const MapLayers: React.FC<IProps> = ({ children }) => {
  const { width: windowWith } = useWindowDimensions();
  const isDesktop = windowWith >= 1024;
  const centerPosition: LatLngExpression = isDesktop
    ? [46.312, 44.2558]
    : [46.3, 44.2558];
  const [currentZoom, setZoom] = useState(isDesktop ? 14 : 12);
  return (
    <MapContainer
      zoomControl={false}
      attributionControl={false}
      className="z-10 w-full h-full"
      center={centerPosition}
      zoom={currentZoom}
      minZoom={8}
      maxBounds={maskCoordinates}
      scrollWheelZoom={true}
    >
      <MapEvents setZoom={setZoom} />
      <TileLayer
        bounds={maskCoordinates}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      // Заливка белого цвета
      <MapZoomContext.Provider value={currentZoom}>
        {children}
      </MapZoomContext.Provider>
    </MapContainer>
  );
};
