import React, { useState } from "react";
import InputMask from "react-input-mask-format";
import { Controller, useForm } from "react-hook-form";
import { clearPhone } from "@/shared/lib";
import { useLoginConfirmPhoneMutation } from "@/entities/session";
import { useAppDispatch } from "@/shared/model/hooks";
import { useNavigate } from "react-router-dom";
import { changePhoneUser } from "@/entities/user";

interface IFormValues {
  phone: string;
}

export const LoginForm: React.FC = () => {
  const [error, setError] = useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [confirmFetch] = useLoginConfirmPhoneMutation();

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<IFormValues>();

  async function onSubmit({ phone }: IFormValues) {
    if (phone) {
      const cleanedPhone: string = clearPhone(phone);
      try {
        await confirmFetch({ phone: cleanedPhone }).unwrap();
        dispatch(changePhoneUser(cleanedPhone));
        navigate("/auth/login-confirm");
      } catch (error) {
        if (error && typeof error === "string") setError(error);
      }
    }
  }

  return (
    <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
      {errors.phone && (
        <label className="text-error">{errors.phone.message}</label>
      )}
      <Controller
        name="phone"
        control={control}
        defaultValue=""
        rules={{
          required: "Введите номер телефона",
          validate: (value) => {
            if (value.includes("_")) return "Введите номер телефона";
          },
        }}
        render={({ field }) => (
          <InputMask
            {...field}
            className={`input input-bordered ${
              errors.phone ? "input-error" : ""
            }`}
            mask="+7 (999) 999-99-99"
            placeholder="Телефон"
          />
        )}
      />

      {error && <label className="text-error text-center">{error}</label>}

      <button type="submit" className="submit-button" disabled={isSubmitting}>
        {isSubmitting ? (
          <span className="loading loading-spinner loading-sm"></span>
        ) : (
          "Войти"
        )}
      </button>
    </form>
  );
};
