import { TPlace, TTicket, TResImage } from "@/shared/model/types";
import { createSlice } from "@reduxjs/toolkit";
import { userApi } from "../api/user-api";

type TUserInfo = {
  id: number | null;
  avatar: TResImage | null;
  confirmPhone: string | null;
  phone: string | null;
  username: string | null;
  email: string | null;
};

interface IUserSliceState extends TUserInfo {
  tickets: TTicket[] | null;
  favoritePlaces: TPlace[] | null;
  trip: TPlace[] | null;
}

const initialState: IUserSliceState = {
  id: null,
  avatar: null,
  confirmPhone: null,
  phone: null,
  username: null,
  email: null,
  tickets: null,
  favoritePlaces: null,
  trip: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    changePhoneUser: (state, { payload }) => {
      state.confirmPhone = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getUserInfo.matchFulfilled,
      (state: IUserSliceState, { payload }) => {
        state.id = payload.id;
        if (payload.avatar) {
          state.avatar = payload.avatar;
        }
        if (payload.phone) {
          state.phone = payload.phone;
        }
        if (payload.username) {
          state.username = payload.username;
        }
        if (payload.email) {
          state.email = payload.email;
        }
      }
    );
    builder.addMatcher(
      userApi.endpoints.getUserTickets.matchFulfilled,
      (state: IUserSliceState, { payload }) => {
        if (payload.tickets) state.tickets = payload.tickets;
      }
    );
  },
});

export const selectConfirmPhone = (state: RootState) => state.user.confirmPhone;
export const selectAvatar = (state: RootState) => state.user.avatar;
export const selectUsername = (state: RootState) => state.user.username;
export const selectEmail = (state: RootState) => state.user.email;
export const selectPhone = (state: RootState) => state.user.phone;
export const selectUserId = (state: RootState) => state.user.id;
export const selectUserTickets = (state: RootState) => state.user.tickets;

export const { changePhoneUser } = userSlice.actions;
