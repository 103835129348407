import { useFilterByTag } from "../hooks/use-filter-by-tag";

interface IProps {
  setFilterByTags: (filterByTags: string) => void;
  tags: { id: number; title: string }[];
  className?: string;
  width?: string;
}

export const FilterByTagDropdownMenu: React.FC<IProps> = ({
  setFilterByTags,
  tags,
  className = "",
  width = "auto",
}) => {
  const { clearSelectedTags, selectedTags, handleToggleTag } =
    useFilterByTag(setFilterByTags);

  return (
    <details className={`dropdown w-full ${className}`}>
      <summary className={`btn btn-sm w-${width}`}>Фильтры</summary>
      <div
        className={`p-2 w-${width} shadow menu dropdown-content z-[1] bg-base-100 rounded-box max-h-[40vh] overflow-y-auto flex-nowrap`}
      >
        <label className="space-x-2 cursor-pointer label">
          <span className="label-text">Все</span>
          <input
            type="checkbox"
            onChange={clearSelectedTags}
            checked={selectedTags.length === 0}
            className="checkbox checkbox-primary"
          />
        </label>
        {tags?.map((tag) => (
          <label
            className="space-x-2 cursor-pointer label text-nowrap"
            key={tag.id}
          >
            <span className="label-text">{tag.title}</span>
            <input
              onChange={() => handleToggleTag(tag.id)}
              type="checkbox"
              checked={selectedTags?.some((id) => id === tag.id)}
              className="checkbox checkbox-primary"
            />
          </label>
        ))}
      </div>
    </details>
  );
};
