import { ChangeEvent, useState } from "react";

export const useAfishaSearch = () => {
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [nameEvent, setNameEvent] = useState<string>("");
  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };
  const handleNameEvent = (event: ChangeEvent<HTMLInputElement>) => {
    setNameEvent(event.target.value);
  };
  return { selectedDate, handleDateChange, nameEvent, handleNameEvent };
};
