import { FilterByCategoryAndTag } from "@/features/filter-places";
import { useState } from "react";
import { MapLayers } from "./map-layers";
import { TPlace } from "@/shared/model/types";
import { CustomMarker } from "./custom-marker";
import { useGetUserHomeQuery, useTripPlaces } from "@/entities/user";
import {
  selectMethodMovement,
  useGetRouteByPlacesIdsQuery,
} from "@/entities/route";
import { Route } from "./route";
import { useAppSelector } from "@/shared/model/hooks";
import { PopupContent } from "./popup-content";
import { selectIsAuthorized } from "@/entities/session";
import MarkerClusterGroup from "react-leaflet-cluster";
import { getIconUrl } from "@/entities/places";

export const MapWithPlaces: React.FC = () => {
  const [placesData, setPlacesData] = useState<TPlace[]>();
  const { tripPlaces } = useTripPlaces();
  const methodMovement = useAppSelector(selectMethodMovement);

  const isAuth = useAppSelector(selectIsAuthorized);

  const { home } = useGetUserHomeQuery("", {
    selectFromResult: (res) => ({ home: res?.data?.home }),
  });
  const { data: routeData } = useGetRouteByPlacesIdsQuery(
    {
      placesIds: tripPlaces?.map((place: TPlace) => place.id),
      profile: methodMovement,
    },
    {
      skip: !tripPlaces || tripPlaces.length <= 1,
    }
  );

  const isDrawTripPlaces = tripPlaces && isAuth;
  const isDrawTripRoute = tripPlaces?.length > 1 && routeData && isAuth;
  const isDrawHomeIcon =
    isAuth &&
    home &&
    tripPlaces &&
    !tripPlaces?.some((place: TPlace) => place.id === home.id);

  return (
    <div
      className={`w-full ${
        isAuth ? "h-[60vh]" : "h-100vh"
      } lg:h-[100vh] relative`}
    >
      <div className="absolute z-30 px-4 top-4 lg:top-20 max-w-[100%] overflow-x-auto lg:overflow-visible hide-scrollbar">
        <FilterByCategoryAndTag setPlacesData={setPlacesData} />{" "}
      </div>
      <MapLayers>
        <MarkerClusterGroup>
          {placesData &&
            placesData.map((place) => {
              const iconUrl = getIconUrl(place.type, place.icon);
              return (
                <CustomMarker
                  key={place.id}
                  popup={<PopupContent place={place} />}
                  position={{ lat: place.latitude, lng: place.longitude }}
                  color={place?.type?.color || "#ffffff"}
                  iconUrl={iconUrl}
                  zIndex={10}
                  zoomContent={place?.short_title || place?.title}
                />
              );
            })}
        </MarkerClusterGroup>
        {isDrawTripPlaces &&
          tripPlaces.map((place: TPlace, index: number) => {
            let iconUrl = "";
            if (place.id === home?.id) {
              iconUrl = "https://cdn-icons-png.flaticon.com/512/25/25694.png";
            } else {
              iconUrl = getIconUrl(place.type, place.icon);
            }

            return (
              <CustomMarker
                key={place.id}
                popup={<PopupContent place={place} />}
                position={{ lat: place.latitude, lng: place.longitude }}
                color={"#E5AB00"}
                iconUrl={iconUrl}
                indicator={index + 1}
                zIndex={30}
                zoomContent={place?.short_title || place?.title}
              />
            );
          })}
        {isDrawHomeIcon && (
          <CustomMarker
            key={home.id}
            popup={<PopupContent place={home} />}
            position={{ lat: home.latitude, lng: home.longitude }}
            color={"#86efac"}
            iconUrl={"https://cdn-icons-png.flaticon.com/512/25/25694.png"}
            zIndex={20}
            zoomContent={home?.short_title || home?.title}
          />
        )}

        {isDrawTripRoute && <Route geometry={routeData.geometry} />}
      </MapLayers>
    </div>
  );
};
