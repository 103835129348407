import { useCreateApplicationTourMutation } from "@/entities/tours";

type TPropsCreateApplicationTour = {
  name: string;
  tour_id: number;
  phone: string;
  email: string;
  total_price: number;
  customers_count: number;
  tour_schedule: number;
};

export const useJoinTour = () => {
  const [
    createApplicationTourQuery,
    { isSuccess: isSuccessCreatedApplication },
  ] = useCreateApplicationTourMutation();

  async function createApplicationTour(data: TPropsCreateApplicationTour) {
    try {
      await createApplicationTourQuery({ data }).unwrap();
    } catch (error) {
      console.error(error);
    }
  }

  return {
    createApplicationTour,
    isSuccessCreatedApplication,
  };
};
