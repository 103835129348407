import { useEffect, useRef } from "react";
import {
  VKShareButton,
  TelegramShareButton,
  TelegramIcon,
  VKIcon,
  WhatsappShareButton,
  WhatsappIcon,
  ViberShareButton,
  ViberIcon,
} from "react-share";

interface IProps {
  className?: string;
  size?: "sm" | "md";
}
export const ShareButton: React.FC<IProps> = ({
  className = "",
  size = "md",
}) => {
  const detailsRef = useRef<HTMLDetailsElement>(null);
  const currentUrl = window.location.href;

  useEffect(() => {
    document.addEventListener("click", closeDetails);

    return () => {
      document.removeEventListener("click", closeDetails);
    };
  }, []);

  const closeDetails = () => {
    if (detailsRef.current) {
      detailsRef.current.removeAttribute("open");
    }
  };

  return (
    <details
      ref={detailsRef}
      className={`dropdown dropdown-end tooltip tooltip-left ${className}`}
      data-tip="Поделиться"
    >
      <summary className={`text-gray-400 btn btn-circle btn-${size}`}>
        <svg
          className={size === "sm" ? "w-4" : "w-6"}
          fill="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7 0-.24-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92 0-1.61-1.31-2.92-2.92-2.92Z"></path>
        </svg>
      </summary>

      <ul
        tabIndex={0}
        className="z-30 p-2 shadow dropdown-content menu bg-base-100 rounded-box w-52"
      >
        <li>
          <TelegramShareButton url={currentUrl} className="p-2 !important">
            <TelegramIcon size={"100%"} className="w-6" round />
            Телеграм
          </TelegramShareButton>
        </li>
        <li>
          <VKShareButton url={currentUrl} className="p-2 !important">
            <VKIcon size={"100%"} className="w-6" round />
            Вконтакте
          </VKShareButton>
        </li>
        <li>
          <WhatsappShareButton url={currentUrl} className="p-2 !important">
            <WhatsappIcon size={"100%"} className="w-6" round />
            Whatsapp
          </WhatsappShareButton>
        </li>
        <li>
          <ViberShareButton url={currentUrl} className="p-2 !important">
            <ViberIcon size={"100%"} className="w-6" round />
            Viber
          </ViberShareButton>
        </li>
      </ul>
    </details>
  );
};
