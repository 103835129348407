import { SetStateAction, useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { getTodaySchedule, groupSchedule } from "@/shared/lib";
import { TTourSchedule } from "@/shared/model/types";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface IPropsSelectDate {
  schedulesDates: string[];
  setCalendarValue: React.Dispatch<SetStateAction<Value>>;
  value: Value;
  className?: string;
}

const SelectDate: React.FC<IPropsSelectDate> = ({
  schedulesDates,
  setCalendarValue,
  value,
  className = "",
}) => {
  const tileContentDatesWithEvent = ({ date }: { date: Date }) => {
    const formattedDate = DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
    if (schedulesDates.includes(formattedDate)) {
      return (
        <div className="relative">
          <div className="absolute bottom-0 right-0 w-2 h-2 text-white rounded-full bg-primary" />
        </div>
      );
    }
    return null;
  };

  return (
    <Calendar
      tileContent={tileContentDatesWithEvent}
      onChange={setCalendarValue}
      defaultValue={value}
      className={"rounded-xl " + className}
    />
  );
};

interface IProps {
  schedulesData: TTourSchedule[];
  onChangeSchedule: React.Dispatch<
    React.SetStateAction<TTourSchedule | undefined>
  >;
  price: number;
  children?: React.ReactNode;
}

export const SelectTourSchedule: React.FC<IProps> = ({
  onChangeSchedule,
  schedulesData,
  children,
  price,
}) => {
  const defaultValue = getTodaySchedule(schedulesData)?.start_at;
  const [calendarValue, setCalendarValue] = useState<Value>(
    defaultValue ? new Date(defaultValue) : new Date()
  );
  const [selectedScheduleId, setSelectedScheduleId] = useState<number>();
  const groupedSchedule = useMemo(() => {
    return groupSchedule(schedulesData);
  }, [schedulesData]);
  const scheduleSelectedDate = useMemo(() => {
    if (calendarValue && groupedSchedule) {
      const selectedDate = DateTime.fromJSDate(calendarValue as Date).toFormat(
        "yyyy-MM-dd"
      );
      return groupedSchedule[selectedDate];
    }
  }, [calendarValue, groupedSchedule]);

  function handleSelectSchedule(event: React.ChangeEvent<HTMLSelectElement>) {
    const selectedId = parseInt(event.target.value);
    setSelectedScheduleId(selectedId);
  }

  useEffect(() => {
    if (scheduleSelectedDate && scheduleSelectedDate[0])
      setSelectedScheduleId(scheduleSelectedDate[0].id);
    else setSelectedScheduleId(undefined);
  }, [scheduleSelectedDate]);

  useEffect(() => {
    if (selectedScheduleId) {
      const schedule = schedulesData.find(
        (schedule) => schedule.id === selectedScheduleId
      );
      onChangeSchedule(schedule);
    } else onChangeSchedule(undefined);
  }, [selectedScheduleId]);


  return (
    <div className="flex flex-col gap-4 md:flex-row">
      <SelectDate
        value={defaultValue ? new Date(defaultValue) : null}
        className="self-center"
        schedulesDates={Object.keys(groupedSchedule)}
        setCalendarValue={setCalendarValue}
      />
      <div className="flex-1">
        {children}
        {scheduleSelectedDate ? (
          <select
            className="w-full mt-4 select select-bordered"
            onChange={handleSelectSchedule}
            value={selectedScheduleId}
          >
            {scheduleSelectedDate.map((schedule) => (
              <option
                key={schedule.id}
                value={schedule.id}
                className="flex justify-between"
              >
                Время: {DateTime.fromISO(schedule.start_at).toFormat("HH:mm")}
                {", "}
                Цена: {price} руб.
              </option>
            ))}
          </select>
        ) : (
          <p className="mt-4">
            На{" "}
            {DateTime.fromJSDate(calendarValue as Date).toFormat("dd.MM.yyyy")}{" "}
            нет записи, выберите дату с{" "}
            <span className="inline-flex items-center gap-2">
              индикатором{" "}
              <span className="w-2 h-2 text-white rounded-full bg-primary"></span>
            </span>
          </p>
        )}
      </div>
    </div>
  );
};
