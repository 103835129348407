import { useGetAllPlaceCategoriesQuery } from "@/entities/places";
import { Link } from "react-router-dom";

interface IProps {
  className?: string;
}

type TCategory = { id: number; slug: string; title: string };

const BLACK_LIST_SLUGS = ["oteli"];

export const PlacesMenu: React.FC<IProps> = ({ className = "" }) => {
  const { categories } = useGetAllPlaceCategoriesQuery("", {
    selectFromResult: (res) => ({
      categories: res?.data?.data,
    }),
  });

  return (
    <ul className={className}>
      {categories
        ?.filter(
          (category: TCategory) => !BLACK_LIST_SLUGS.includes(category.slug)
        )
        .map((category: TCategory) => (
          <li key={category.id}>
            <Link to={"/places/list/" + category.slug}>{category.title}</Link>
          </li>
        ))}
    </ul>
  );
};
