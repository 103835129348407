import { Modal } from "@/shared/ui";
import { DeleteButton } from "./delete-button";

interface IProps {
  children: React.ReactNode;
}

const DeleteModalContent: React.FC = () => {
  return (
    <div className="flex flex-col gap-2">
      <p>Вы уверены что хотите удалить свой аккаунт?</p>
      <p>
        Данные удаленного аккаунт{" "}
        <span className="text-error">нельзя будет восстановить</span>.
      </p>
      <div className="flex items-end justify-between">
        <DeleteButton>Удалить окончательно</DeleteButton>
        <div className="modal-action">
          <form method="dialog">
            <button className="btn">Отменить</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export const DeleteModal: React.FC<IProps> = ({ children }) => {
  return <Modal content={<DeleteModalContent />}>{children}</Modal>;
};
