import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RailwayTicketsSearch = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/train-tickets");
  }, [navigate]);

  return <></>;
};
export default RailwayTicketsSearch;
