import { baseApi } from "@/shared/api";

export const readyRoutesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllReadyRoutes: build.query({
      query: ({ populate = "" }: { populate?: string }) => ({
        url: `/ready-routes?${populate}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAllReadyRoutesQuery } = readyRoutesApi;
