import { selectIsAuthorized } from "@/entities/session";
import { useAppSelector } from "@/shared/model/hooks";
import { Background, Container } from "@/shared/ui";
import { ProfileNavbar } from "@/widgets/account";
import { ErrorBoundary } from "@/widgets/layouts";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export const Profile: React.FC = () => {
  const isAuth = useAppSelector(selectIsAuthorized);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuth) navigate("/auth/registration");
  }, [isAuth]);

  return (
    <ErrorBoundary>
      <Background type={3} />
      <ProfileNavbar />
      <Container className="mt-5 md:mt-10">
        <div className="flex flex-col items-center h-full gap-4">
          <Outlet />
        </div>
      </Container>
    </ErrorBoundary>
  );
};
