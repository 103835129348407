import { Account } from "@/widgets/account";
import { Header } from "@/widgets/layouts/ui/header";
import { ErrorBoundary } from "@sentry/react";

export const Hotels: React.FC = () => {
  return (
    <ErrorBoundary>
      <Header Account={<Account />} />
      <iframe
        src="https://hotels.elistory.ru"
        className="hide-scrollbar w-full h-screen"
      ></iframe>
    </ErrorBoundary>
  );
};
