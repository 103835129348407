import {
  useJoinFreeEventMutation,
  usePayEventMutation,
} from "@/entities/events";
import { selectUserId } from "@/entities/user";
import { useAppSelector } from "@/shared/model/hooks";
import { TEvent } from "@/shared/model/types";
import { useEffect, useState } from "react";

type TJoinEventProps = {
  personsCount: number;
  scheduleId: number;
  event: TEvent;
};

export const useJoinEvent = () => {
  const [joinFreeEventQuery, { isLoading: joinFreeEventQueryStatus }] =
    useJoinFreeEventMutation();
  const [payEventQuery, { isLoading: payEventQueryLoadingStatus }] =
    usePayEventMutation();
  const userId = useAppSelector(selectUserId);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    if (joinFreeEventQueryStatus || payEventQueryLoadingStatus) {
      setIsFetching(true);
    } else {
      setIsFetching(false);
    }
  }, [joinFreeEventQueryStatus, payEventQueryLoadingStatus]);

  async function joinFreeEvent({
    personsCount,
    scheduleId,
    event,
  }: TJoinEventProps) {
    if (scheduleId && userId && event.id && personsCount) {
      const available_persons_count =
        event.max_persons_count -
        event?.tickets?.reduce(
          (sum, { persons_count }) => sum + persons_count,
          0
        );
      if (available_persons_count >= personsCount) {
        await joinFreeEventQuery({
          users_permissions_user: userId,
          event: Number(event.id),
          schedule_id: scheduleId,
          persons_count: personsCount,
        });
      } else {
        return Promise.reject(
          new Error(
            "Слишком много участников, сейчас доступно мест: " +
              available_persons_count
          )
        );
      }
    }
  }

  async function payEvent({
    personsCount,
    scheduleId,
    event,
  }: TJoinEventProps) {
    if (scheduleId && userId && event.id && personsCount) {
      const redirectUrl = window.location.hostname + "/profile/tickets";
      await payEventQuery({
        user_id: userId,
        seats_ids: [],
        event_id: event.id,
        schedule_id: scheduleId,
        persons_count: personsCount,
        payment_payload: {
          payment_method_type: "bank_card",
          return_url: redirectUrl,
        },
      });
    }
  }
  return {
    payEvent,
    joinFreeEvent,
    isFetching,
  };
};
