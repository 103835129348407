import { useState } from "react";
import { RouteMode } from "./route-mode";
import { Trip } from "./trip";
import { FavoritePlaces } from "./favorite-places";

export const MobileActionsBar: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<"trip" | "favorite">("trip");

  function handleTripTab() {
    setSelectedTab("trip");
  }

  function handleFavoriteTab() {
    setSelectedTab("favorite");
  }

  return (
    <div className="z-20 bottom-0 absolute h-[60vh] w-full bg-base-100 flex flex-col neutralize-mobile-menu">
      <div className={`flex flex-col h-full`}>
        <div role="tablist" className="flex tabs tabs-boxed tabs-sm">
          <button
            role="tab"
            className={`tab flex-1 ${
              selectedTab === "trip" ? "tab-active" : ""
            }`}
            onClick={handleTripTab}
          >
            Маршрут
          </button>
          <button
            role="tab"
            className={`tab flex-1 ${
              selectedTab === "favorite" ? "tab-active" : ""
            }`}
            onClick={handleFavoriteTab}
          >
            Избранное
          </button>
        </div>
        {selectedTab === "trip" && (
          <>
            <Trip />
            <RouteMode />
          </>
        )}
        {selectedTab === "favorite" && <FavoritePlaces />}
      </div>
    </div>
  );
};
