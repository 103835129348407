import { THall, TSchedule, TTicket } from "@/shared/model/types";
import { Modal } from "@/shared/ui";
import { Hall } from "./hall";

interface IProps extends TSchedule {
  children: React.ReactNode;
  tickets: TTicket[];
  hall?: THall;
}

export const ModalWithHall: React.FC<IProps> = ({
  id,
  hall,
  price,
  children,
  tickets,
}) => {
  return (
    <>
      {hall && (
        <Modal
          className="max-w-5xl "
          content={
            <Hall scheduleId={id} price={price} hall={hall} tickets={tickets} />
          }
        >
          {children}
        </Modal>
      )}
    </>
  );
};
