import { useEffect, useState } from "react";
import qs from "qs";

export const useFilterByTag = (
  setFilterByTags: (filterByTags: string) => void
) => {
  const [selectedTags, setSelectedTags] = useState<number[]>([]);

  useEffect(() => {
    if (selectedTags.length === 0) {
      setFilterByTags("");
    } else {
      setFilterByTags(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  const filter = qs.stringify({
    filters: {
      $and: selectedTags.map((tagId) => ({
        $and: [{ tags: { id: tagId } }],
      })),
    },
  });

  function clearSelectedTags() {
    setSelectedTags([]);
  }

  function handleToggleTag(id: number) {
    setSelectedTags((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  }
  return { selectedTags, handleToggleTag, clearSelectedTags };
};
