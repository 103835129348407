import { useParams } from "react-router-dom";
import { BreadCrumbs, Loading } from "@/shared/ui";
import { useGetArticleByIdQuery } from "@/entities/articles";
import { ArticleDetails } from "@/entities/articles";

export const ArticleInfo: React.FC = () => {
  const { id } = useParams();
  const { articleData, isLoading, isFetching } = useGetArticleByIdQuery(
    { id: Number(id) },
    {
      selectFromResult: (res) => ({
        articleData: res?.data?.data,
        ...res,
      }),
      skip: !id,
    }
  );

  if (isLoading || isFetching || !articleData)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading size="lg" />
      </div>
    );

  return (
    <div className="max-w-[1000px] mx-auto">
      <BreadCrumbs />
      <ArticleDetails articleData={articleData} />
    </div>
  );
};
