import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";
import ReactInputMask from "react-input-mask-format";
import { IFormValues } from "../model/types";

interface IProps {
  formRegister: UseFormRegister<IFormValues>;
  fieldErrors: FieldErrors<IFormValues>;
  control: Control<IFormValues>;
}

export const ApplicationFormInputs: React.FC<IProps> = ({
  formRegister,
  fieldErrors,
  control,
}) => {
  return (
    <div className="flex flex-col w-full gap-2">
      {fieldErrors.name && (
        <label className="text-error">{fieldErrors.name.message}</label>
      )}
      <input
        type="text"
        placeholder="ФИО"
        className={`input input-bordered w-full ${
          fieldErrors.name ? "input-error" : ""
        }`}
        {...formRegister("name", {
          required: "Введите имя фамилию и отчество",
        })}
      />

      {fieldErrors.phone && (
        <label className="text-error">{fieldErrors.phone.message}</label>
      )}
      <Controller
        name="phone"
        control={control}
        defaultValue=""
        rules={{
          required: "Введите номер телефона",
          validate: (value) => {
            if (value.includes("_")) return "Введите номер телефона";
          },
        }}
        render={({ field }) => (
          <ReactInputMask
            {...field}
            className={`input input-bordered w-full ${
              fieldErrors.phone ? "input-error" : ""
            }`}
            mask="+7 (999) 999-99-99"
            placeholder="Телефон"
          />
        )}
      />

      {fieldErrors.email && (
        <label className="text-error">{fieldErrors.email.message}</label>
      )}
      <input
        type="text"
        placeholder="Почта"
        className={`input input-bordered w-full ${
          fieldErrors.email ? "input-error" : ""
        }`}
        {...formRegister("email", {
          required: "Введите Email",
          pattern: {
            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            message: "Email не верно указан",
          },
        })}
      />
    </div>
  );
};
