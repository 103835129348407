import { Background, Container } from "@/shared/ui";
import { ErrorBoundary } from "@/widgets/layouts";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export const Tours: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/home");
  }, []);

  return (
    <ErrorBoundary>
      <Background type={3} />
      <Container direction="col" className="h-full">
        <Outlet />
      </Container>
    </ErrorBoundary>
  );
};
