import { useTripPlaces } from "@/entities/user";

interface IProps {
  placeId: number;
  className?: string;
  size?: "sm" | "md";
  Content?: JSX.Element;
  isTooltip?: boolean;
  btnClasses?: string;
}
export const ToggleTripPlacesButton: React.FC<IProps> = ({
  placeId,
  className = "",
  size = "md",
  Content,
  btnClasses = "",
  isTooltip = false,
}) => {
  const { checkingIsTripPlace, toggleTripPlace } = useTripPlaces();
  const isAdded = checkingIsTripPlace(placeId);

  if (isTooltip)
    return (
      <div
        className={`tooltip tooltip-left ${className}`}
        data-tip={isAdded ? "Убрать из маршрута" : "Добавить в маршрут"}
      >
        <button
          onClick={() => toggleTripPlace(placeId)}
          className={`btn ${btnClasses} ${size === "md" ? "btn-md" : "btn-sm"}`}
        >
          {Content
            ? Content
            : isAdded
            ? "Убрать из маршрута"
            : "Добавить в маршрут"}
        </button>
      </div>
    );

  return (
    <button
      onClick={() => toggleTripPlace(placeId)}
      className={`btn  ${btnClasses} ${
        size === "md" ? "btn-md" : "btn-sm"
      } text-nowrap ${isAdded ? "" : "btn-primary"}`}
    >
      {Content
        ? Content
        : isAdded
        ? "Убрать из маршрута"
        : "Добавить в маршрут"}
    </button>
  );
};
