import { clearSessionData } from "@/entities/session";
import { useAppDispatch } from "@/shared/model/hooks";
import { Details } from "@/shared/ui";

interface IProps {
  className?: string;
}

export const LogoutButton: React.FC<IProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch();

  function logout() {
    dispatch(clearSessionData());
  }

  return (
    <Details>
      <summary>
        <button className={`text-start ${className}`}>Выйти</button>
      </summary>
      <ul>
        <h2 className="menu-title">Точно выйти?</h2>
        <li>
          <button onClick={logout}>Да</button>
        </li>
        <li>
          <a>Нет</a>
        </li>
      </ul>
    </Details>
  );
};
