import { useGetAllReadyRoutesQuery } from "../api/ready-routes-api";

export const useReadyRoutes = () => {
  const { readyRoutes } = useGetAllReadyRoutesQuery(
    { populate: "populate=*" },
    {
      selectFromResult(res) {
        return { readyRoutes: res?.data?.data };
      },
    }
  );

  return { readyRoutes };
};
