/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";

interface IDataItem {
  id: number;
  [key: string]: any;
}

interface SearchComponentProps {
  data: IDataItem[];
  searchField: string;
  setFilteredData: (filtered: any) => void;
}

export const SearchInput: React.FC<SearchComponentProps> = ({
  data,
  searchField,
  setFilteredData,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearch = (term: string) => {
    if (term) {
      const filtered = data.filter((item) =>
        item[searchField].toLowerCase().includes(term.toLowerCase())
      );
      setFilteredData(filtered);
    } else setFilteredData(data);
  };

  const debouncedSearch = (
    callback: (...args: any[]) => void,
    delay: number
  ) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => callback(...args), delay);
    };
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(handleSearch, 300)(term);
  };

  return (
    <input
      className="w-full mt-2 input input-bordered"
      type="text"
      placeholder="Поиск по названию"
      value={searchTerm}
      onChange={handleChange}
    />
  );
};
