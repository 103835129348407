import { useGetStaticQuery } from "@/entities/static";
import AndroidIcon from "@/shared/assets/icons/android.svg?react";
import AppleIcon from "@/shared/assets/icons/apple.svg?react";
import QRCode from "react-qr-code";

interface IProps {
  text?: string;
  className?: string;
}

export const DownloadApp: React.FC<IProps> = ({
  text = "Скачайте мобильное приложение, чтобы получить больше возможностей",
  className = "",
}) => {
  const { data } = useGetStaticQuery("");

  return (
    <div className={`text-black text-start ${className}`}>
      <h2 className="fb">Мобильное приложение</h2>
      <p className="text-lg">{text}</p>
      <div className="flex flex-col flex-wrap gap-4 mt-4 md:flex-row">
        <div className="flex-1 flex flex-col items-center text-center">
          {data && data?.androidLink ? (
            <QRCode
              className="hidden md:inline-block"
              style={{ height: "auto" }}
              value={data.androidLink}
            />
          ) : (
            <div className="flex-grow flex items-center">
              <p className="text-gray-500">
                Приложение все еще в разработке, совсем скоро оно станет
                доступно на Android
              </p>
            </div>
          )}
          <a
            className={`w-full mt-4 btn btn-outline md:w-auto text-black ${
              data?.androidLink ? "" : "!btn-disabled"
            }`}
            href={data?.androidLink}
          >
            <AndroidIcon />
            Скачать на Android
          </a>
        </div>

        <div className="flex-1 flex flex-col items-center text-center">
          {data && data?.appleLink ? (
            <QRCode
              className="hidden md:inline-block"
              style={{ height: "auto" }}
              value={data.appleLink}
            />
          ) : (
            <div className="flex-grow flex items-center">
              <p className="text-gray-500">
                Приложение все еще в разработке, совсем скоро оно станет
                доступно на IOS.
              </p>
            </div>
          )}
          <a
            className={`w-full mt-4 btn btn-outline md:w-auto text-black ${
              data?.appleLink ? "" : "!btn-disabled"
            }`}
            href={data?.appleLink}
          >
            <AppleIcon /> Скачать на IOS
          </a>
        </div>
      </div>
    </div>
  );
};
