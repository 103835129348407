import React from "react";
import ReactDOM from "react-dom/client";
import "./globals.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./app-router";
import { Provider } from "react-redux";
import { appStore } from "./app-store";
import * as Sentry from "@sentry/react";
import { YMInitializer } from "react-yandex-metrika";

Sentry.init({
  dsn: "https://36afe802b7df6a9700d720ea8df08c98@o4506984652734464.ingest.us.sentry.io/4506984656601088",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  enabled: process.env.NODE_ENV !== "development",
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={appStore}>
      {import.meta.env.MODE !== "development" && (
        <YMInitializer accounts={[92829965]} options={{ webvisor: true }} />
      )}
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
