import { TImage, TPlace, TResImage } from "@/shared/model/types";
import {
  Collapse,
  ContactInfo,
  Hero,
  Price,
  Rating,
  Tags,
  TypeBadge,
  WithIcon,
} from "@/shared/ui";
import MapIcon from "@/shared/assets/icons/map.svg?react";

import Rub from "@/shared/assets/icons/rub.svg?react";
import { LockUnlock } from "./lock-unlock";
import { ShareButton } from "@/shared/ui/share-button";

interface IProps {
  placeData: TPlace;
  FavoriteButton?: JSX.Element;
  children?: React.ReactNode;
}
export const PlaceDetails: React.FC<IProps> = ({
  placeData,
  FavoriteButton,
  children,
}) => {
  const images = placeData?.images?.map(
    (image: TResImage): TImage => ({
      id: image.id,
      url:
        image?.formats?.medium?.url ||
        image?.formats?.small?.url ||
        image?.formats?.thumbnail?.url,
    })
  );

  return (
    <>
      {images && (
        <Hero
          images={images}
          TypeBadge={
            <TypeBadge color={placeData?.type?.color}>
              {placeData?.type?.title}
            </TypeBadge>
          }
        >
          <div className="flex h-full flex-col">
            <div className="flex items-center justify-between">
              <h3 className="text-2xl fb">{placeData?.title}</h3>
              <div className="flex gap-2 ">
                {FavoriteButton}
                <ShareButton size="sm" />
              </div>
            </div>
            <pre className="flex-grow overflow-y-auto basis-10 mt-2 hidden lg:block">
              {placeData?.description}
            </pre>
          </div>
        </Hero>
      )}

      <div className="flex flex-col gap-4 lg:flex-row">
        <div className="flex flex-col w-full gap-2 lg:mt-6 lg:w-1/2 ">
          {placeData?.audio_guide?.url && (
            <audio
              src={placeData?.audio_guide.url}
              controls
              className="w-full"
            />
          )}

          <div className={`flex flex-col gap-2 p-4 rounded-lg bg-base-200 `}>
            <WithIcon Icon={<MapIcon className="w-6 text-primary" />}>
              {placeData?.address}
            </WithIcon>
            <LockUnlock
              working_hours={placeData?.working_hours}
              working_hours_weekends={placeData?.working_hours_weekends}
            />
            {placeData?.average_price !== 0 && (
              <WithIcon Icon={<Rub className="w-6 text-primary" />}>
                Средний чек{" "}
                <Price isApproximately price={placeData?.average_price} />
              </WithIcon>
            )}
            <Rating id={placeData?.id} rating={placeData?.rating} />
          </div>
          <ContactInfo
            phone={placeData?.organizational_phone}
            email={placeData?.email}
            site={placeData?.site}
            className="w-full "
          />
          <Tags tags={placeData?.tags} className="w-full" />
        </div>

        <div className="flex flex-col w-full gap-2 lg:mt-6 lg:w-1/2 ">
          {children}

          <div className="block lg:hidden">
            {placeData?.description.length > 300 ? (
              <Collapse>
                <pre>{placeData?.description}</pre>
              </Collapse>
            ) : (
              <pre>{placeData?.description}</pre>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
