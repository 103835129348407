import { baseApi } from "@/shared/api";
import { TQuery } from "../model/types";

export const toursApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllTours: build.query({
      query: ({
        fields = "",
        populate = "",
        filter = "",
        page = 1,
        pageSize = 12,
      }: TQuery) => ({
        url: `/tours?${populate}&${fields}&${filter}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
        method: "GET",
      }),
    }),
    getTourById: build.query({
      query: ({ id }: { id: number }) => ({
        url: `/tours/${id}?populate=*`,
        method: "GET",
      }),
    }),
    createApplicationTour: build.mutation({
      query: (data) => ({
        url: "/applications-tours",
        method: "POST",
        body: data,
      }),
      transformErrorResponse(res) {
        if (res.status !== 200)
          return "Не удалось создать заявку на тур, пожалуйста попробуйте позднее.";
      },
    }),
  }),
});

export const {
  useGetAllToursQuery,
  useGetTourByIdQuery,
  useCreateApplicationTourMutation,
} = toursApi;
