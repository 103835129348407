import { useFavoritePlaces } from "@/entities/user";
import { useWindowDimensions } from "@/shared/hooks";

interface IProps {
  placeId: number;
  className?: string;
  size?: "sm" | "md";
  CustomIcon?: JSX.Element;
  isButtonWithText?: boolean;
}
export const FavoriteButton: React.FC<IProps> = ({
  placeId,
  className = "",
  size = "md",
  isButtonWithText = false,
  CustomIcon,
}) => {
  const { checkingIsFavoritePlace, toggleFavoritePlace } = useFavoritePlaces();
  const isFavorite = checkingIsFavoritePlace(placeId);
  const { width: windowWith } = useWindowDimensions();
  const isDesktop = windowWith >= 1024;

  if (isButtonWithText)
    return (
      <button
        onClick={() => toggleFavoritePlace(placeId)}
        className={`btn ${size === "md" ? "btn-md" : "btn-sm"} text-nowrap ${
          isFavorite ? "" : "btn-primary"
        }`}
      >
        {isFavorite ? "Убрать из избранного" : "Добавить в избранное"}
      </button>
    );
  if (isDesktop)
    return (
      <div
        className={`tooltip tooltip-left ${className}`}
        data-tip={isFavorite ? "убрать из избранного" : "Добавить в избранное"}
      >
        <button
          onClick={() => toggleFavoritePlace(placeId)}
          className={`btn btn-circle text-base-200 ${
            size === "md" ? "btn-md" : "btn-sm"
          }`}
        >
          {CustomIcon || (
            <svg
              className={`${size === "md" ? "w-6" : "w-4"} ${
                isFavorite ? "text-primary" : "text-gray-400"
              }`}
              fill="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20.206 4.793a5.938 5.938 0 0 0-4.21-1.754 5.9 5.9 0 0 0-3.995 1.558 5.904 5.904 0 0 0-6.279-1.1 5.942 5.942 0 0 0-1.93 1.3c-2.354 2.363-2.353 6.06.001 8.412L12 21.416l8.207-8.207c2.354-2.353 2.355-6.049-.002-8.416Z"></path>
            </svg>
          )}
        </button>
      </div>
    );

  return (
    <button
      onClick={() => toggleFavoritePlace(placeId)}
      className={`btn btn-circle text-base-200 ${
        size === "md" ? "btn-md" : "btn-sm"
      }`}
    >
      {CustomIcon || (
        <svg
          className={`${size === "md" ? "w-6" : "w-4"} ${
            isFavorite ? "text-primary" : "text-gray-400"
          }`}
          fill="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M20.206 4.793a5.938 5.938 0 0 0-4.21-1.754 5.9 5.9 0 0 0-3.995 1.558 5.904 5.904 0 0 0-6.279-1.1 5.942 5.942 0 0 0-1.93 1.3c-2.354 2.363-2.353 6.06.001 8.412L12 21.416l8.207-8.207c2.354-2.353 2.355-6.049-.002-8.416Z"></path>
        </svg>
      )}
    </button>
  );
};
