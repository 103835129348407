import { TInformationBlock } from "@/shared/model/types";

interface IProps {
  block: TInformationBlock;
}
export const InformationBlock: React.FC<IProps> = ({ block }) => {
  return (
    <div className="flex flex-col gap-2">
      <h4 className="fb">{block?.title}</h4>
      <div className="flex flex-wrap gap-2">
        {block?.images?.map((image) => {
          const url = image.formats?.small?.url || image.formats.thumbnail.url;
          return (
            <img
              src={url}
              key={image.id}
              className="rounded max-h-[300px] md:max-h-[200px] lg:max-h-[300px] h-full"
            />
          );
        })}
      </div>
      <pre className="mt-2">{block.content}</pre>
      <div className="flex gap-2">
        {block?.block_links?.map((link) => (
          <a key={link.id} href={link.url} className="link" target="_blank">
            {link.title}
          </a>
        ))}
      </div>
    </div>
  );
};
