import { useGetUserHomeQuery, useTripPlaces } from "@/entities/user";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";
import { Dropdown, Price } from "@/shared/ui";
import { LockUnlock, getIconUrl } from "@/entities/places";
import { Link } from "react-router-dom";
import { ToggleTripPlacesButton } from "@/features/trip-place";
import TrashIcon from "@/shared/assets/icons/trash.svg?react";
import MoveItemIcon from "@/shared/assets/icons/move-item.svg?react";
import { TPlace } from "@/shared/model/types";
import { useEffect, useState } from "react";
import { useWindowDimensions } from "@/shared/hooks";

const Card: React.FC<{ place: TPlace; homeId: number }> = ({
  place,
  homeId,
}) => {
  const { width: windowWith } = useWindowDimensions();
  const isDesktop = windowWith >= 1024;

  let iconUrl = getIconUrl(place.type, place.icon);

  let color = place?.type?.color || "#ffffff";

  if (place.id === homeId) {
    iconUrl = "https://cdn-icons-png.flaticon.com/512/25/25694.png";
    color = "#E5AB00";
  }

  return (
    <div className="flex items-start justify-between w-full mb-3">
      <div className="flex w-full gap-2 ">
        <img
          src={iconUrl}
          style={{ backgroundColor: color }}
          className={`rounded-xl p-1 w-[40px] object-cover h-[40px] border`}
        />
        <div className="flex justify-between flex-1 w-full px-2 py-1 rounded-xl bg-base-200">
          <div>
            <p className="fb text-sm lg:text-base">{place.title}</p>
            <Dropdown
              content={
                <div className="flex flex-col py-2">
                  <LockUnlock
                    working_hours={place.working_hours}
                    working_hours_weekends={place.working_hours_weekends}
                    isOnlyText
                  />
                  <div className="flex">
                    <p>Средний чек:</p>
                    <Price isApproximately price={place.average_price} />
                  </div>
                  <Link to={"/places/" + place.id} className="link">
                    Перейти на страницу
                  </Link>
                </div>
              }
              className="-mt-1"
              textButton={<button className="link">скрыть</button>}
              collapsedTextButton={<button className="link">подробнее</button>}
            />
          </div>
          <ToggleTripPlacesButton
            placeId={place.id}
            size="sm"
            btnClasses="btn-circle mt-1"
            Content={<TrashIcon className="w-4 text-primary" />}
            isTooltip={isDesktop}
          />
        </div>
      </div>
      <div className="cursor-grab flex items-center justify-center w-[40px] h-[40px] rounded-full">
        <MoveItemIcon className="w-6 text-primary" />
      </div>
    </div>
  );
};

export const Trip = () => {
  const { tripPlaces, setTripPlaces } = useTripPlaces();
  const [listItems, setListItems] = useState<TPlace[]>();

  const { home } = useGetUserHomeQuery("", {
    selectFromResult: (res) => ({ home: res?.data?.home }),
  });

  useEffect(() => {
    const isEqualPlaceIds =
      JSON.stringify(listItems) === JSON.stringify(tripPlaces);
    if (!isEqualPlaceIds) {
      setListItems(tripPlaces);
    }
  }, [tripPlaces]);

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newItems: TPlace[] = Array.from(tripPlaces);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setListItems(newItems);

    const ids = newItems.map(({ id }) => id);
    await setTripPlaces(ids);
  };

  return (
    <div className="flex flex-col flex-grow gap-4 px-2 pt-2 overflow-y-auto">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <ul
              className="flex flex-col"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {listItems &&
                listItems.map((place: TPlace, index: number) => (
                  <Draggable
                    key={place.id}
                    draggableId={place.id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Card homeId={home?.id} place={place} />
                      </li>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
