import { useState } from "react";
import { Modal } from "@/shared/ui";
import { ApplicationForm } from "./application-form";

interface IProps {
  id: number;
  price?: number;
}

const ModalContent: React.FC<IProps> = ({ id, price }) => {
  const [isApplicationCreated, setIsApplicationCreated] =
    useState<boolean>(false);

  function handleApplicationCreatedSuccess(): void {
    setIsApplicationCreated(true);
  }

  return (
    <>
      <h3 className="text-xl text-center fb">Снять номер</h3>

      {isApplicationCreated ? (
        <>
          <div role="alert" className="mt-4 alert alert-success">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 stroke-current shrink-0"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span>
              Запись успешно создана! В скоре с вами свяжется менеджер отеля для
              подтверждения брони номера.
            </span>
          </div>
          <form method="dialog" className="mt-4">
            <button className="w-full btn">Закрыть</button>
          </form>
        </>
      ) : (
        <ApplicationForm
          id={id}
          price={price}
          handleApplicationCreatedSuccess={handleApplicationCreatedSuccess}
        />
      )}
    </>
  );
};

export const ModalForm: React.FC<IProps> = ({ id, price }) => {
  return (
    <Modal content={<ModalContent id={id} price={price} />}>
      <button className="w-full btn btn-base-200">Снять номер</button>
    </Modal>
  );
};
