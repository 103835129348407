interface IProps {
  children: React.ReactNode;
  className?: string;
}

export const ListGrid: React.FC<IProps> = ({ children, className = "" }) => {
  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-4 lg:gap-4 ${className}`}
    >
      {children}
    </div>
  );
};
