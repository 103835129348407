import { Loading, Price, Title, WithIcon } from "@/shared/ui";
import { useGetAllGuidesQuery } from "../api/guides-api";
import { TGuide } from "../model/types";
import { Link } from "react-router-dom";
import PhoneIcon from "@/shared/assets/icons/phone.svg?react";
import EmailIcon from "@/shared/assets/icons/email.svg?react";

export const GuidesList: React.FC = () => {
  const { guidesData, isLoading, isFetching } = useGetAllGuidesQuery(
    {
      populate: "populate=avatar",
    },
    {
      selectFromResult: (res) => ({
        guidesData: res?.data?.data,
        ...res,
      }),
    }
  );

  if (isLoading || isFetching)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading size="lg" />
      </div>
    );

  return (
    <div className="flex flex-col flex-grow">
      <Title>Гиды</Title>
      <div className="flex flex-col items-center flex-1 gap-4">
        {guidesData &&
          guidesData.map((guide: TGuide) => {
            const avatarUrl =
              guide.avatar?.formats?.medium?.url ||
              guide.avatar?.formats?.small?.url ||
              guide.avatar?.formats?.thumbnail?.url ||
              "https://waterpolo.ru/images/no-foto-avatar.png";

            return (
              <div className="max-w-[720px] w-full" key={guide.id}>
                <div className="flex gap-4 ">
                  <div className="avatar">
                    <div className="w-40 rounded ">
                      <img src={avatarUrl} className="bg-base-200" />
                    </div>
                  </div>
                  <div className="flex flex-col justify-between flex-grow sm:flex-row">
                    <div className="flex flex-col ">
                      <h3 className="text-xl fb">{guide.name}</h3>

                      <div className="mt-2">
                        <WithIcon
                          Icon={<PhoneIcon className="w-6 text-primary" />}
                        >
                          {guide.phone && (
                            <a href={`tel:${guide.phone}`}>{guide.phone}</a>
                          )}
                        </WithIcon>
                        <WithIcon
                          Icon={<EmailIcon className="w-6 text-primary" />}
                        >
                          {guide.email && (
                            <a href={`mailto:${guide?.email}`}>{guide.email}</a>
                          )}
                        </WithIcon>
                      </div>
                      <Price className="mt-4" price={guide.price} />
                    </div>
                    <Link
                      className="mt-2 btn btn-primary"
                      to={"/guides/" + guide.id}
                    >
                      Подробнее
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
