import { useAppSelector } from "@/shared/model/hooks";
import { useGetUserTicketsQuery } from "../api/user-api";
import { selectUserId } from "..";
import { TTicket } from "@/shared/model/types";

export const useTickets = () => {
  const userId = useAppSelector(selectUserId);

  const { userTickets, isLoading, error } = useGetUserTicketsQuery("", {
    selectFromResult(res) {
      return {
        userTickets: res?.data?.tickets,
        ...res,
      };
    },
    skip: !userId,
  });

  function checkingIsMyTicket(eventId: number): boolean {
    if (
      userTickets &&
      userTickets.some((ticket: TTicket) => ticket.event?.id === eventId)
    )
      return true;
    return false;
  }

  return { checkingIsMyTicket, userTickets, isLoading, error };
};
