import { useParams } from "react-router-dom";
import { BreadCrumbs, Collapse, Loading, WithIcon } from "@/shared/ui";
import { useGetOrganizationByIdQuery } from "../api/organizations-api";
import EmailIcon from "@/shared/assets/icons/email.svg?react";
import { getCurrentDateISO } from "@/shared/lib";
import { ShareButton } from "@/shared/ui/share-button";
import { TOrganization } from "../model/types";
import { EventsGrid, useGetEventsQuery } from "@/entities/events";
import qs from "qs";

export const OrganizationDetails: React.FC = () => {
  const { id } = useParams();
  const currentDate = getCurrentDateISO();

  const { organizationData, isLoading, isFetching } =
    useGetOrganizationByIdQuery(
      { id: Number(id) },
      {
        selectFromResult(res) {
          const organizationData: TOrganization = res?.data?.data;
          return { organizationData, ...res };
        },
        skip: !id,
      }
    );

  const queryParams = qs.stringify({
    filters: {
      organization: {
        id: { $eq: organizationData?.id },
      },
      date_delition: {
        $gt: currentDate,
      },
    },
    populate: "preview_image, schedules",
    // fields: "title",
    pagination: { page_size: 100 },
  });

  const { events } = useGetEventsQuery(queryParams, {
    skip: !organizationData?.id,
    selectFromResult: (res) => ({
      events: res?.data?.data,
    }),
  });

  if (isLoading || isFetching)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading size="lg" />
      </div>
    );

  const avatarUrl =
    organizationData?.avatar?.formats?.medium?.url ||
    organizationData?.avatar?.formats?.small?.url ||
    organizationData?.avatar?.formats?.thumbnail?.url ||
    "https://cdn.icon-icons.com/icons2/2483/PNG/512/organization_icon_149905.png";

  return (
    <div className="flex flex-col items-center">
      <div className="w-full">
        <BreadCrumbs />
        {organizationData && (
          <div className="">
            <div className="flex gap-4">
              <div className="avatar">
                <div className="w-40 rounded">
                  <img src={avatarUrl} className="bg-base-200" />
                </div>
              </div>
              <div className="flex flex-col flex-grow">
                <h3 className="text-xl fb">{organizationData.title}</h3>
                <div className="mt-2">
                  {/* <WithIcon Icon={<PhoneIcon className="w-6 text-primary" />}>
                    {organizationData.phone && (
                      <a href={`tel:${organizationData.phone}`}>{organizationData.phone}</a>
                    )}
                  </WithIcon> */}
                  <WithIcon Icon={<EmailIcon className="w-6 text-primary" />}>
                    {organizationData.email && (
                      <a href={`mailto:${organizationData?.email}`}>
                        {organizationData.email}
                      </a>
                    )}
                  </WithIcon>
                </div>
              </div>
              <div className="absolute right-4 top-2 md:relative md:right-0 md:top-0">
                <ShareButton />
              </div>
            </div>

            {/* <ul className="mt-4">
              {organizationData?.links &&
                organizationData?.links.map((link) => (
                  <li key={link.id}>
                    <a href={link.url} target="_blank" className="link">
                      {link.title}
                    </a>
                  </li>
                ))}
            </ul> */}

            <div className="mt-4 ">
              {organizationData?.description &&
              organizationData?.description.length > 300 ? (
                <Collapse>
                  <pre>{organizationData?.description}</pre>
                </Collapse>
              ) : (
                <pre>{organizationData?.description}</pre>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="divider"></div>

      {events && events.length > 0 && <EventsGrid events={events} />}
    </div>
  );
};
