// import { HomeSlider } from "@/entities/home-slider";
// import { Background } from "@/shared/ui";
// import { Plates } from "./plates";
// import { ArticlesHome } from "@/widgets/articles";
// import { ReadyRoutesHorizontal } from "@/entities/ready-routes";
import { HomeBanner } from "@/widgets/home-banner";
import { MobileAppBanner } from "@/widgets/mobile-app-banner";
import { RecommendedEvents } from "@/entities/recommended-events";
import { InterestingPlaces } from "@/entities/interesting-places";
import { PopularRoutes } from "@/entities/popular-routes";
import { UsefulArticles } from "@/entities/useful-articles";

export const Home: React.FC = () => {
  return (
    <div className="neutralize-main-padding-top-content">
      <HomeBanner />
      <RecommendedEvents />
      <UsefulArticles />
      <PopularRoutes />
      <MobileAppBanner />
      <InterestingPlaces />
      {/* <Background /> */}
      {/* <HomeSlider /> */}
      {/* <Plates /> */}
      {/* <ReadyRoutesHorizontal className="mt-20" /> */}
      {/* <ArticlesHome className="mt-20" /> */}
    </div>
  );
};
