import { baseApi } from "@/shared/api";
import { SESSION_TAG, USER_TAG } from "@/shared/api/tags";

export const sessionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (body) => ({
        url: `/auth/v2/local`,
        method: "POST",
        body,
      }),
      invalidatesTags: [SESSION_TAG, USER_TAG],
      transformErrorResponse(baseQueryReturnValue) {
        if (
          baseQueryReturnValue.status === 403 ||
          baseQueryReturnValue.status === 401
        )
          return "Не верный код подтверждения";
      },
    }),
    registration: build.mutation({
      query: (body) => ({
        url: `/auth/v2/local/register`,
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        if (baseQueryReturnValue.status === 400)
          return "Пользователь с таким именем, почтой или телефоном уже существует";
      },
    }),
    loginConfirmPhone: build.mutation({
      query: (body) => ({
        url: `/auth/request-code`,
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        if (
          baseQueryReturnValue.status === 400 ||
          baseQueryReturnValue.status === 404
        )
          return "Пользователь не найден";
      },
    }),
    registrationConfirmPhone: build.mutation({
      query: (body) => ({
        url: `/auth/confirmation/phone`,
        method: "POST",
        body,
      }),
      invalidatesTags: [SESSION_TAG, USER_TAG],
      transformErrorResponse(baseQueryReturnValue) {
        if (baseQueryReturnValue.status === 400)
          return "Не верный код подтверждения";
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useRegistrationMutation,
  useLoginConfirmPhoneMutation,
  useRegistrationConfirmPhoneMutation,
} = sessionApi;
