import { baseApi } from "@/shared/api";
import { IStatic, IStaticResponse } from "../model/types";

export const staticApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getStatic: build.query({
      query: () => ({
        url: `/static`,
        method: "GET",
      }),
      transformResponse(res: IStaticResponse): IStatic {
        return {
          phone: res.data.phone,
          email: res.data.email,
          androidLink: res.data.mobile_app_link_android,
          appleLink: res.data.mobile_app_link_apple,
        };
      },
    }),
    getStaticArticles: build.query({
      query: () => ({
        url: `/static?populate=privacy_policy, use_conditions_service`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetStaticQuery, useGetStaticArticlesQuery } = staticApi;
