interface IProps {
  children: React.ReactNode;
  Icon: JSX.Element;
}
export const WithIcon: React.FC<IProps> = ({ children, Icon }) => {
  if (!children) return <></>;
  return (
    <div className="flex items-center gap-2">
      <div>{Icon}</div>
      <div>{children}</div>
    </div>
  );
};
