import { useState } from "react";
import ArrowDropdownIcon from "../assets/icons/arrow-dropdown.svg?react";

interface IProps {
  children: React.ReactNode;
  className?: string;
}
export const Collapse: React.FC<IProps> = ({ children, className = "" }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  function toggleCollapse() {
    setIsCollapsed((prev) => !prev);
  }

  return (
    <div className={className}>
      <div
        className={`overflow-hidden ${
          isCollapsed ? "max-h-28 md:max-h-20" : ""
        } min-h-20`}
      >
        {children}
      </div>
      <button onClick={toggleCollapse} className="flex mt-2 link">
        {isCollapsed ? "Развернуть" : "Свернуть"}
        {isCollapsed ? (
          <ArrowDropdownIcon className="w-5" />
        ) : (
          <ArrowDropdownIcon className="w-5 rotate-180" />
        )}
      </button>
    </div>
  );
};
