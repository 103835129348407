import { useReadyRoutes } from "@/entities/ready-routes";
import { useTripPlaces } from "@/entities/user";
import { TReadyRoute } from "@/shared/model/types";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

export const ReadyRouteModal: React.FC = () => {
  const modalRef = useRef<HTMLDialogElement | null>(null);
  const { tripPlaces, addTripPlaces } = useTripPlaces();
  const { readyRoutes } = useReadyRoutes();
  const { isShowReadyRoutes } = useParams();

  function addReadyRouteUserTrip(readyRoute: TReadyRoute) {
    const placesIds = readyRoute.places.map(({ id }) => id);
    if (placesIds && placesIds.length !== 0) {
      addTripPlaces(placesIds);
      hideModal();
    }
  }

  useEffect(() => {
    if (tripPlaces && tripPlaces.length === 0) showModal();
  }, [tripPlaces]);

  useEffect(() => {
    if (isShowReadyRoutes === "ready-routes") {
      showModal();
    }
  }, [isShowReadyRoutes]);

  function showModal() {
    if (modalRef.current) modalRef.current.showModal();
  }
  function hideModal() {
    if (modalRef.current) modalRef.current.close();
  }

  return (
    <>
      {tripPlaces && tripPlaces.length === 0 && (
        <button className="mt-2 link" onClick={showModal}>
          Выбрать один из готовых
        </button>
      )}
      <dialog ref={modalRef} className="modal">
        <div className={`modal-box max-w-5xl`}>
          <h3 className="fb">Создай свой маршрут или выбери один из готовых</h3>

          <div className="flex flex-col w-full gap-4 mt-8">
            {readyRoutes &&
              readyRoutes?.map((route: TReadyRoute, index: number) => {
                const imageUrl =
                  route.preview_image?.formats?.small?.url ||
                  route.preview_image?.formats?.thumbnail?.url;
                return (
                  <div
                    className="flex gap-2 flex-col-reverse lg:flex-row bg-base-200 rounded-lg lg:bg-transparent"
                    key={route.id}
                  >
                    <div className="flex-grow p-2">
                      <h3 className="fb">
                        {index + 1}. {route.title}
                      </h3>
                      <pre>{route.description}</pre>
                      <button
                        type="button"
                        onClick={() => addReadyRouteUserTrip(route)}
                        className=" btn btn-outline btn-sm btn-primary"
                      >
                        Добавить маршрут
                      </button>
                    </div>
                    <img
                      src={imageUrl}
                      className="object-cover w-full lg:w-5/12 rounded-lg aspect-video"
                      alt=""
                    />
                  </div>
                );
              })}
          </div>
          <div className="w-full modal-action">
            <form method="dialog" className="w-full">
              <p className="mt-2 text-lg fb">
                Так же вы можете собрать свой маршрут
              </p>
              <button className="w-full mt-2 btn btn-primary">
                Создать маршрут
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </>
  );
};
