import {
  useGetAllPlaceCategoriesQuery,
  useGetAllPlacesQuery,
} from "@/entities/places";
import { TPlace } from "@/shared/model/types";
import { useEffect, useState } from "react";
import { FilterByTagDropdownMenu } from "..";
import { selectIsAuthorized } from "@/entities/session";
import { useAppSelector } from "@/shared/model/hooks";
import { useWindowDimensions } from "@/shared/hooks";

interface IProps {
  setPlacesData: (data: TPlace[]) => void;
}

export const FilterByCategoryAndTag: React.FC<IProps> = ({ setPlacesData }) => {
  const [categoryFilter, setCategoryFilter] = useState<string>("");
  const [tagFilter, setTagFilter] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [isAll, setIsAll] = useState(true);
  const [isNothing, setIsNothing] = useState(false);
  const isAuth = useAppSelector(selectIsAuthorized);
  const { width: windowWith } = useWindowDimensions();
  const isDesktop = windowWith >= 1024;

  const { placesData } = useGetAllPlacesQuery(
    {
      populate:
        "populate=working_hours,working_hours_weekends,type.icon,icon",
      page: 1,
      pageSize: 9999,
      fields:
        "fields[0]=id&fields[1]=title&fields[2]=short_title&fields[3]=latitude&fields[4]=longitude&fields[5]=average_price",
      filter: categoryFilter + "&" + tagFilter,
    },
    {
      selectFromResult: (res) => ({
        placesData: res?.data?.data,
      }),
    }
  );

  const { categories } = useGetAllPlaceCategoriesQuery("", {
    selectFromResult: (res) => ({
      categories: res?.data?.data,
    }),
  });

  function cleanPlacesData() {
    setPlacesData([]);
    setSelectedCategory("");
    setIsAll(false);
    setIsNothing(true);
  }

  useEffect(() => {
    if (placesData) setPlacesData(placesData);
  }, [placesData]);

  useEffect(() => {
    if (selectedCategory)
      setCategoryFilter(
        `filters[$and][0][category][slug][$eq]=${selectedCategory}`
      );
  }, [selectedCategory]);

  return (
    <ul className="flex w-full lg:flex-wrap gap-2 select-none">
      <li
        className={`px-4 py-1 shadow cursor-pointer rounded-full text-nowrap  ${
          isAll ? "bg-primary" : "bg-base-100"
        }`}
        onClick={() => {
          setIsAll(true);
          setIsNothing(false);
          setCategoryFilter("");
          setSelectedCategory("");
          setTagFilter("");
          setPlacesData(placesData);
        }}
      >
        Все
      </li>
      {isAuth && (
        <li
          className={`px-4 py-1 shadow cursor-pointer rounded-full text-nowrap ${
            isNothing ? "bg-primary" : "bg-base-100"
          }`}
          onClick={cleanPlacesData}
        >
          Только маршрут
        </li>
      )}

      {categories &&
        categories.map(
          (category: {
            id: number;
            slug: string;
            title: string;
            place_tags: { id: number; title: string }[];
          }) => (
            <li
              className={`px-4 py-1 shadow  cursor-pointer rounded-full relative text-nowrap ${
                selectedCategory === category.slug
                  ? "bg-primary"
                  : "bg-base-100"
              }`}
              key={category.id}
              onClick={() => {
                setIsAll(false);
                setIsNothing(false);
                setSelectedCategory(category.slug);
              }}
            >
              {category.title}
              {selectedCategory === category.slug && isDesktop && (
                <FilterByTagDropdownMenu
                  setFilterByTags={setTagFilter}
                  tags={category.place_tags}
                  className="absolute left-0 w-auto inline-block -bottom-[110%] z-40 [&>summary]:rounded-full [&>summary]:bg-base-100 [&>summary]:shadow"
                />
              )}
            </li>
          )
        )}
    </ul>
  );
};
