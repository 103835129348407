import { Dispatch, SetStateAction } from "react";

interface IProps {
  page: number;
  totalPages: number;
  setPage: Dispatch<SetStateAction<number>>;
}

type TPaginationButtons = {
  number: number;
  isActive: boolean;
};

export const Pagination: React.FC<IProps> = ({ totalPages, page, setPage }) => {
  function scrollToUpPage() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  function changePageHandler(pageNumber: number) {
    setPage(pageNumber);
    scrollToUpPage();
  }

  function incrementPageHandler() {
    setPage(page + 1);
    scrollToUpPage();
  }

  function decrementPageHandler() {
    setPage(page - 1);
    scrollToUpPage();
  }

  const paginationButtons: TPaginationButtons[] = Array.from(
    { length: totalPages },
    (_, index) => ({
      number: index + 1,
      isActive: page === index + 1,
    })
  );

  if (totalPages === 1 || totalPages === 0) return <></>;

  return (
    <div className="flex w-full p-2 mt-4 rounded-lg bg-base-100 ">
      <button
        className="btn-outline btn btn-sm"
        disabled={page - 1 === 0}
        onClick={decrementPageHandler}
      >
        назад
      </button>
      <div className="flex justify-center flex-grow gap-1 flex-wrap ">
        {paginationButtons.map(({ number, isActive }) => (
          <button
            key={number}
            onClick={() => {
              changePageHandler(number);
            }}
            className={`join-item btn btn-sm ${isActive ? "btn-primary" : ""}`}
          >
            {number}
          </button>
        ))}
      </div>
      <button
        className="btn-outline btn btn-sm"
        disabled={page === totalPages}
        onClick={incrementPageHandler}
      >
        далее
      </button>
    </div>
  );
};
