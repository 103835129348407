import { TExcursion, TExcursionSchedule } from "@/shared/model/types";
import { Counter, Loading, Modal } from "@/shared/ui";
import { useMemo, useState } from "react";
import { IFormValues } from "../model/types";
import { useForm } from "react-hook-form";
import { ApplicationFormInputs } from "./application-form-inputs";
import { useJoinExcursion } from "../hooks/use-join-excursion";
import { SelectExcursionSchedule } from "./select-excursion-schedule";
import { getCurrentSchedules } from "@/shared/lib";
import { Link } from "react-router-dom";

interface IProps {
  excursionData: TExcursion;
  btnText?: string;
}

const joinEventButtonCSSClasses = "text-lg btn btn-primary font-[400] w-full";

export const JoinExcursion: React.FC<IProps> = ({
  excursionData,
  btnText = "Записаться на мероприятие",
}) => {
  return (
    <Modal
      className="max-w-3xl"
      content={<ModalContent excursionData={excursionData} />}
    >
      <button className={joinEventButtonCSSClasses}>{btnText}</button>
    </Modal>
  );
};

const ModalContent: React.FC<IProps> = ({ excursionData }) => {
  const [selectedSchedule, setSelectedSchedule] =
    useState<TExcursionSchedule>();
  const { createApplicationExcursion, isSuccessCreatedApplication } =
    useJoinExcursion();
  const [customersCount, setCustomersCount] = useState(1);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<IFormValues>();

  async function onSubmit(formData: IFormValues) {
    if (selectedSchedule) {
      const { id: scheduleId } = selectedSchedule;
      const totalPrice = excursionData.price * customersCount;
      const { name, phone, email } = formData;
      const data = {
        name,
        phone,
        email,
        excursion_id: excursionData.id,
        total_price: totalPrice,
        customers_count: customersCount,
        excursion_schedule: scheduleId,
      };
      await createApplicationExcursion(data);
    }
  }

  const currentSchedule = useMemo(() => {
    if (excursionData && excursionData.excursion_schedules) {
      return getCurrentSchedules(excursionData.excursion_schedules);
    }
  }, [excursionData]);

  if (isSuccessCreatedApplication)
    return (
      <>
        <div role="alert" className="mt-4 alert alert-success">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 stroke-current shrink-0"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>
            Заявка успешно создана! В скоре с вами свяжется организатор
            экскурсии для подтверждения и оплаты поездки.
          </span>
        </div>
        <form method="dialog" className="mt-4">
          <button className="w-full btn">Закрыть</button>
        </form>
      </>
    );

  return (
    <form
      className="flex flex-col gap-2 mt-4 text-base"
      onSubmit={handleSubmit(onSubmit)}
    >
      {currentSchedule && (
        <SelectExcursionSchedule
          price={excursionData.price}
          onChangeSchedule={setSelectedSchedule}
          schedulesData={currentSchedule as TExcursionSchedule[]}
        >
          {selectedSchedule && (
            <>
              <ApplicationFormInputs
                control={control}
                formRegister={register}
                fieldErrors={errors}
              />
              <Counter
                className="mt-2"
                value={customersCount}
                onChangeCount={setCustomersCount}
              />
              <p className="mt-4">
                Гид:{" "}
                <Link
                  target="_blank"
                  className="link"
                  to={`/guides/${selectedSchedule?.guide.id}`}
                >
                  {selectedSchedule?.guide?.name}
                </Link>
              </p>
            </>
          )}
        </SelectExcursionSchedule>
      )}
      <button
        type="submit"
        className="submit-button"
        disabled={isSubmitting || !selectedSchedule}
      >
        {isSubmitting ? (
          <Loading size="sm" />
        ) : selectedSchedule ? (
          "Записаться (" + excursionData?.price * customersCount + " руб.)"
        ) : (
          "Выберете дату и время экскурсии"
        )}
      </button>
    </form>
  );
};
