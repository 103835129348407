import ButtonSearch from "@/shared/ui/button-search";
import SwapArrows from "@/shared/assets/icons/swap-arrows.svg?react";
import { useAirTicketsSearch } from "../hooks/use-air-tickets-search";



const AirTicketsSearch = () => {

  const {
    cities,
    fromValue,
    toValue,
    fromCityId,
    toCityId,
    departureDate,
    returnDate,
    passengers,
    swapValues,
    handleFromChange,
    handleToChange,
    handleDepartureDateChange,
    handleReturnDateChange,
    decreasePassengers,
    increasePassengers,
  } = useAirTicketsSearch();

  return (
    <div className="flex flex-col gap-4 md:gap-10 md:flex-row items-center w-full">
      <select
        className="select select-bordered w-full md:flex-grow"
        value={fromValue}
        onChange={handleFromChange}
      >
        <option disabled>Откуда</option>
        {cities.map((city) => (
          <option key={city.id}>{city.name}</option>
        ))}
      </select>

      <button onClick={swapValues}>
        <SwapArrows className="w-5" />
      </button>

      <select
        className="select select-bordered w-full "
        value={toValue}
        onChange={handleToChange}
      >
        <option disabled>Куда</option>
        {cities.map((city) => (
          <option key={city.id}>{city.name}</option>
        ))}
      </select>

      <div className="flex items-center gap-3 w-full md:w-auto">
        <p className="w-16">Туда</p>
        <label className="input input-bordered flex items-center gap-2 flex-grow">
          <input
            type="date"
            className="grow"
            placeholder="Найти дату"
            onChange={handleDepartureDateChange}
          />
        </label>
      </div>
      <div className="flex items-center gap-3 w-full md:w-auto">
        <p className="w-16">Обратно</p>
        <label className="input input-bordered flex items-center gap-2 flex-grow">
          <input
            type="date"
            className="grow"
            placeholder="Найти дату"
            onChange={handleReturnDateChange}
          />
        </label>
      </div>

      <div className="join flex w-full md:w-auto">
        <button
          className="join-item btn flex-grow md:flex-grow-0"
          onClick={decreasePassengers}
        >
          -
        </button>

        <button className="join-item btn flex-grow md:flex-grow-0 no-animation">
          Пассажиров: {passengers}
        </button>

        <button
          className="join-item btn flex-grow md:flex-grow-0"
          onClick={increasePassengers}
        >
          +
        </button>
      </div>
      <a
        href={`https://airtickets.elistory.ru/results/a${fromCityId}c${toCityId}${departureDate}${returnDate}ADT${passengers}-class=Economy`}
        className="w-full md:flex-grow"
      >
        <ButtonSearch className="bg-primary text-white w-full md:w-auto">
          Найти
        </ButtonSearch>
      </a>
    </div>
  );
};
export default AirTicketsSearch;