import { Background, Container } from "@/shared/ui";
import { ErrorBoundary } from "@/widgets/layouts";
import { Outlet } from "react-router-dom";

export const TrainTickets: React.FC = () => {
  return (
    <ErrorBoundary>
      <Background type={3} />
      <Container direction="col" className="h-full">
        <Outlet />
      </Container>
    </ErrorBoundary>
  );
};
