import { Modal, Price, Rating } from "@/shared/ui";
import HomeIcon from "@/shared/assets/icons/home.svg?react";
import { useGetAllPlacesQuery } from "@/entities/places";
import { TPlace } from "@/shared/model/types";
import { Link } from "react-router-dom";
import { SearchInput } from "@/features/search-by-field";
import { useEffect, useState } from "react";
import { SelectHome } from "@/features/update-profile";
import { useGetUserHomeQuery } from "@/entities/user";

const Hotels: React.FC = () => {
  const { placesData } = useGetAllPlacesQuery(
    {
      populate: "populate=preview_image",
      pageSize: 9999,
      filter: "filters[$and][0][category][title][$eq]=Отели",
    },
    {
      selectFromResult: (res) => ({
        placesData: res?.data?.data,
      }),
    }
  );

  const [SearchedHotels, setSearchedHotels] = useState(placesData);

  useEffect(() => {
    if (placesData) setSearchedHotels(placesData);
  }, [placesData]);

  return (
    <>
      <h3 className="fb">Укажите ваш отель</h3>
      <p>
        Для удобства построения маршрутов выберите гостиницу в которой вы
        остановились
      </p>
      <SearchInput
        setFilteredData={setSearchedHotels}
        data={placesData}
        searchField="title"
      />

      {SearchedHotels?.length === 0 && (
        <p className="mt-4 text-center">Отелей с таким названием не найдено</p>
      )}
      {SearchedHotels?.map((hotel: TPlace) => {
        const imageUrl =
          hotel.preview_image?.formats?.small?.url ||
          hotel.preview_image?.formats?.thumbnail?.url;
        return (
          <div className="flex gap-2 mt-4" key={hotel.id}>
            <img
              src={imageUrl}
              className="object-cover w-5/12 rounded-lg aspect-video"
              alt=""
            />
            <div className="flex-grow">
              <div className="flex justify-between">
                <h3 className="fb">{hotel.title}</h3>
                <Rating id={hotel.id} rating={hotel.rating} />
              </div>
              {hotel.average_price && hotel.average_price !== 0 && (
                <div>
                  Средняя цена:{" "}
                  <Price isApproximately price={hotel.average_price} />
                </div>
              )}
              <Link to={"/places/hotels/" + hotel.id} className="block link">
                Перейти на страницу отеля
              </Link>
              <SelectHome placeId={hotel.id} />
            </div>
          </div>
        );
      })}
    </>
  );
};

export const HotelsModal: React.FC = () => {
  const { home } = useGetUserHomeQuery("", {
    selectFromResult: (res) => ({ home: res?.data?.home }),
  });

  return (
    <Modal
      content={
        <>
          <Hotels />
          <form method="dialog">
            <button className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2">
              ✕
            </button>
          </form>
        </>
      }
      className="w-7/12 max-w-5xl"
    >
      <button
        className={`link flex gap-2 ${home ? " text-white " : "text-primary "}`}
      >
        <HomeIcon className="w-6 text-green-300" />{" "}
        {home?.title ? (
          <p className="w-full truncate max-w-40">{home?.title}</p>
        ) : (
          "Выбрать отель"
        )}
      </button>
    </Modal>
  );
};
