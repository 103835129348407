import { useState } from "react";
import { Pagination } from "@/features/pagination";
import { Loading, Title } from "@/shared/ui";
import { EventsGrid, useGetAllEventsQuery } from "@/entities/events";
import { getCurrentDateISO } from "@/shared/lib";
import {
  FilterByDate,
  FilterByTypes,
  FilterByTypesDropdownMenu,
} from "@/features/filter-events";
import { useWindowDimensions } from "@/shared/hooks";
import { useAppSelector } from "@/shared/model/hooks";
import { selectIsOpenFromMobileApp } from "@/entities/session";
import { useSearchParams } from "react-router-dom";

export const EventsList: React.FC = () => {
  const [searchParams] = useSearchParams();
  const date = searchParams.get("date");
  const nameEvent = searchParams.get("nameEvent");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [filterByTypes, setFilterByTypes] = useState<string>("");
  const [filterByDate, setFilterByDate] = useState<string>("");
  const { width: windowWith } = useWindowDimensions();
  const isDesktop = windowWith >= 1024;
  const currentDate = getCurrentDateISO();
  const isOpenFromMobileApp = useAppSelector(selectIsOpenFromMobileApp);
  const { totalPages, eventsData, isLoading, isFetching } =
    useGetAllEventsQuery(
      {
        filter: `filters[$and][0][date_delition][$gt]=${currentDate}&${filterByDate}&${filterByTypes}${
          nameEvent ? "&_q=" + nameEvent : ""
        }`,
        populate: "populate=preview_image, schedules",
        page: pageNumber,
        pageSize: 12,
      },
      {
        selectFromResult: (res) => {
          return {
            totalPages: res?.data?.meta?.pagination?.pageCount,
            eventsData: res?.data?.data,
            ...res,
          };
        },
      }
    );

  return (
    <div className="flex flex-col flex-grow">
      {!isOpenFromMobileApp && <Title>События</Title>}
      <FilterByDate defaultDate={date} setFilterByDate={setFilterByDate} />
      {isDesktop ? (
        <FilterByTypes setFilterByTypes={setFilterByTypes} className="mt-4" />
      ) : (
        <FilterByTypesDropdownMenu
          setFilterByTypes={setFilterByTypes}
          className="mt-4"
          width="full"
        />
      )}
      <div className="flex-1 mt-4">
        {eventsData && <EventsGrid events={eventsData} />}
        {isLoading ||
          (isFetching && (
            <div className="flex items-center justify-center w-full h-full">
              <Loading size="lg" />
            </div>
          ))}
      </div>

      {totalPages > 0 && (
        <Pagination
          totalPages={totalPages}
          page={pageNumber}
          setPage={setPageNumber}
        />
      )}
    </div>
  );
};
