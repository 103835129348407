/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link, useMatches } from "react-router-dom";

interface IMatches {
  id: string;
  pathname: string;
  data: unknown;
  handle: {
    crumb: (param?: string) => { path: string; title: string }[];
  };
}

export const BreadCrumbs: React.FC = () => {
  const matches: any = useMatches();
  const crumbs: { path: string; title: string }[] = matches
    .filter((match: IMatches) => Boolean(match?.handle?.crumb))
    .map((match: IMatches) => match.handle?.crumb)[0];

  return (
    <div className="text-sm breadcrumbs">
      <ul>
        {crumbs?.map((crumb) => (
          <li key={crumb.path}>
            <Link to={crumb.path}>{crumb.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
