import { Link } from "react-router-dom";
import ButtonSearch from "@/shared/ui/button-search";
import ArrowDropdown from "@/shared/assets/icons/arrow-dropdown.svg?react";
import useRestaurantsSearch from "../hooks/use-restaurants-search";

const RestaurantsSearch = () => {
  const {
    currentKitchen,
    desiredRestaurant,
    enCurrentKitchen,
    handleSearchRestaurant,
    setCurrentKitchen,
    setEnCurrentKitchen,
    kitchens,
  } = useRestaurantsSearch();

  return (
    <div className="flex flex-col gap-4 md:flex-row items-center  w-full">
      <input
        type="text"
        placeholder="Найти ресторан или кафе"
        className="input input-bordered w-full"
        onChange={handleSearchRestaurant}
      />
      <div className="dropdown dropdown-top w-full md:w-44">
        <div tabIndex={0} role="button" className="btn w-full p-0">
          {currentKitchen ? currentKitchen : "Кухня"}
          <ArrowDropdown className="w-4" />
        </div>
        <ul
          tabIndex={0}
          className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
        >
          {kitchens.map((kitchen, index) => (
            <li
              key={index}
              onClick={() => {
                setCurrentKitchen(kitchen.slagRu);
                setEnCurrentKitchen(kitchen.slagEn);
              }}
            >
              <a>{kitchen.slagRu}</a>
            </li>
          ))}
        </ul>
      </div>
      <Link
        to={`/places/list/gastronomiya${
          enCurrentKitchen && desiredRestaurant
            ? `?slag=${enCurrentKitchen}&nameRestaurant=${desiredRestaurant}`
            : enCurrentKitchen
            ? `?slag=${enCurrentKitchen}`
            : desiredRestaurant
            ? `?nameRestaurant=${desiredRestaurant}`
            : ``
        }`}
        className="w-full md:w-40"
      >
        <ButtonSearch className="bg-primary text-white w-full">
          Найти
        </ButtonSearch>
      </Link>
    </div>
  );
};
export default RestaurantsSearch;

// [
//   "Вегетарианская",
//   "Грузинская",
//   "Европейская",
//   "Кавказская",
//   "Калмыцкая",
//   "Паназиатская",
//   "Русская",
// ];
