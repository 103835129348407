import { baseApi } from "@/shared/api";
import { TQuery } from "../model/types";

export const guidesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllGuides: build.query({
      query: ({ populate, page = 1, pageSize = 999 }: TQuery) => ({
        url: `/guides?${populate}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
        method: "GET",
      }),
    }),
    getGuideById: build.query({
      query: ({ id }: { id: number }) => ({
        url: `/guides/${id}?populate=*`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAllGuidesQuery, useGetGuideByIdQuery } = guidesApi;
