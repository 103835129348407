import { LatLngExpression } from "leaflet";
import polyline from "polyline";
import { useMemo } from "react";
import { Polyline } from "react-leaflet";

interface IProps {
  geometry: string;
}

export const Route: React.FC<IProps> = ({ geometry }) => {
  const decodedPolyline: LatLngExpression[][] = useMemo(() => {
    const decodedGeometry = polyline.decode(
      geometry
    ) as unknown as LatLngExpression[][];

    return decodedGeometry.map((point) => [
      point[0] as unknown as number,
      point[1] as unknown as number,
    ]) as unknown as LatLngExpression[][];
  }, [geometry]);

  return <Polyline weight={8} positions={decodedPolyline} color="#0f53ff" />;
};
