import { baseApi } from "@/shared/api";
import { TBodyJoinFreeEvent, TBodyPaySeats, TQuery } from "../model/types";
import { EVENT_TAG, TICKETS_TAG } from "@/shared/api/tags";

export const eventsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllEvents: build.query({
      query: ({
        fields = "",
        populate = "",
        filter = "",
        page = 1,
        pageSize = 12,
      }: TQuery) => ({
        url: `/events?${populate}&${fields}&${filter}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
        method: "GET",
      }),
      providesTags: [TICKETS_TAG],
    }),
    getEvents: build.query({
      query: (queryParams: string) => ({
        url: `/events?${queryParams}`,
        method: "GET",
      }),
    }),
    getEventById: build.query({
      query: ({ id }: { id: number }) => ({
        url: `/events/${id}?populate=images&populate=hall.seats&populate=schedules&populate=place, tickets.seats, website_link, organization`,
        method: "GET",
      }),
      providesTags: [TICKETS_TAG],
    }),
    payEvent: build.mutation({
      query: (body: TBodyPaySeats) => ({
        url: `/event-payment`,
        method: "POST",
        body,
      }),
      transformResponse(value) {
        if (
          value &&
          typeof value === "object" &&
          "redirect_url" in value &&
          typeof value.redirect_url === "string"
        )
          window.location.href = value.redirect_url;
      },
      transformErrorResponse(value) {
        if (value.status === 400)
          return "Места заняты пожалуйста, обновите страницу и выберете снова";
        return "Непредвиденная ошибка, пожалуйста попробуйте позже";
      },
      invalidatesTags: [EVENT_TAG, TICKETS_TAG],
    }),

    joinFreeEvent: build.mutation({
      query: (data: TBodyJoinFreeEvent) => ({
        url: `/tickets`,
        method: "POST",
        body: { data: { link_pay: "", is_paid: true, ...data } },
      }),
      transformErrorResponse(value) {
        if (value.status !== 200)
          return "Непредвиденная ошибка, пожалуйста попробуйте позже";
      },
      invalidatesTags: [TICKETS_TAG],
    }),
  }),
});

export const {
  useGetAllEventsQuery,
  useGetEventByIdQuery,
  usePayEventMutation,
  useJoinFreeEventMutation,
  useGetEventsQuery,
} = eventsApi;
