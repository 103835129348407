import { useUpdateViewCounterMutation } from "../api/articles-api";

export const useArticles = () => {
  const [updateViewCounter] = useUpdateViewCounterMutation();

  async function incrementViewCounter(id: number, counter: number) {
    if (id && typeof counter === "number") {
      await updateViewCounter({ id, count: counter + 1 });
    }
  }

  return { incrementViewCounter };
};
