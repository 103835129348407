import { selectPhone } from "@/entities/user";
import { DeleteModal } from "@/features/delete-my-accaunt";
import {
  UpdateAvatar,
  UpdateEmail,
  UpdateUsername,
} from "@/features/update-profile";
import { useAppSelector } from "@/shared/model/hooks";

export const ProfileDetails: React.FC = () => {
  const userPhone = useAppSelector(selectPhone);

  return (
    <div className="max-w-[38rem] w-full space-y-2">
      <UpdateAvatar />
      <UpdateUsername />
      <UpdateEmail />
      <p className="mt-2 text-gray-400">
        Телефон: <span className="text-black fb">{userPhone}</span>
      </p>
      <DeleteModal>
        <button className="mt-2 btn"> Удалить аккаунт</button>
      </DeleteModal>
    </div>
  );
};
