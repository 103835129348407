import { Link } from "react-router-dom";
import { Container } from "@/shared/ui";
import DiagonalArrow from "@/shared/assets/icons/diagonal-arrow.svg?react";
import ArticleCard from "@/shared/ui/article-card";
import { useRecommendedEvents } from "../hooks/use-recommended-events";

const RecommendedEvents = () => {
  const { events } = useRecommendedEvents();
  return (
    <Container className="mt-10">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold">Рекомендованные события</h1>
        <Link to={"/events"}>
          <button className="btn hidden md:flex bg-white border border-primary font-sans">
            Смотреть больше
            <DiagonalArrow />
          </button>
        </Link>
      </div>
      <div className="flex gap-[5%] justify-start overflow-x-auto">
        {events &&
          events.map((item, index) => (
            <div className="w-full md:w-[30%] shrink-0" key={index}>
              <Link to={`/events/${item.id}`}>
                <ArticleCard imageSrc={item.preview_image.url} altText="Shoes">
                  <h2 className="card-title">{item.title}</h2>
                  <p className="text-gray-500 line-clamp-3">
                    {item.description}
                  </p>
                </ArticleCard>
              </Link>
            </div>
          ))}
      </div>
      <Link to={"/events"}>
        <button className="btn flex mx-auto md:hidden bg-white border border-primary font-sans mt-5">
          Смотреть больше
          <DiagonalArrow />
        </button>
      </Link>
    </Container>
  );
};
export default RecommendedEvents;
