import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ErrorBoundary, MainLayout } from "@/widgets/layouts";
import { useEffect } from "react";
import { useAppSelector } from "@/shared/model/hooks";
import { selectIsAuthorized } from "@/entities/session";
import { Account } from "@/widgets/account";
import ym from "react-yandex-metrika";

interface IProps {
  isAuthCheck?: boolean;
}

export const Root: React.FC<IProps> = ({ isAuthCheck = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuth = useAppSelector(selectIsAuthorized);

  useEffect(() => {
    if (import.meta.env.MODE !== "development") ym("hit", window.location.href);
  }, [location.pathname]);

  useEffect(() => {
    if (isAuthCheck && isAuth) navigate("/welcome");
  }, [isAuthCheck, isAuth, navigate]);

  return (
    <MainLayout Account={<Account />}>
      <ScrollRestoration />
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </MainLayout>
  );
};
