import { useState } from "react";
import { FavoritePlaces } from "./favorite-places";
import DropdownIcon from "@/shared/assets/icons/arrow-dropdown.svg?react";
import { ReadyRouteModal } from "./ready-route-modal";
import { RouteMode } from "./route-mode";
import { Trip } from "./trip";
import { HotelsModal } from "./hotels-modal";

export const Sidebar: React.FC = () => {
  const [isCollapsedFavorite, setIsCollapsedFavorite] =
    useState<boolean>(false);

  function toggleCollapseFavorite() {
    setIsCollapsedFavorite((prev) => !prev);
  }

  return (
    <div className="h-[100vh] w-[400px] bg-base-100 pt-[4.5rem] flex flex-col">
      <div
        className={`${
          isCollapsedFavorite ? "flex-grow" : "h-[50%]"
        } flex flex-col`}
      >
        <div className="flex items-center justify-between gap-2 px-4 bg-base-300">
          <h2 className="py-2 text-white">Маршрут</h2>
          <HotelsModal />
        </div>
        <ReadyRouteModal />
        <Trip />
        <RouteMode />
      </div>
      <div className={`${isCollapsedFavorite ? "" : "h-[50%]"} flex flex-col`}>
        <div className="flex items-center justify-between px-4 bg-base-300">
          <h2 className="py-2 text-white ">Избранные</h2>
          <button className="btn btn-sm" onClick={toggleCollapseFavorite}>
            {isCollapsedFavorite ? "Развернуть" : "Скрыть"}
            {isCollapsedFavorite ? (
              <DropdownIcon className="w-5 rotate-180" />
            ) : (
              <DropdownIcon className="w-5" />
            )}
          </button>
        </div>
        {!isCollapsedFavorite && <FavoritePlaces />}
      </div>
    </div>
  );
};
