import { Loading, Title, WithIcon } from "@/shared/ui";
import { useGetAllOrganizationsQuery } from "../api/organizations-api";
import { TOrganization } from "../model/types";
import { Link } from "react-router-dom";
import EmailIcon from "@/shared/assets/icons/email.svg?react";

export const OrganizationsList: React.FC = () => {
  const { organizationsData, isLoading, isFetching } =
    useGetAllOrganizationsQuery(
      {
        populate: "populate=avatar",
      },
      {
        selectFromResult: (res) => ({
          organizationsData: res?.data?.data,
          ...res,
        }),
      }
    );

  if (isLoading || isFetching)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading size="lg" />
      </div>
    );

  return (
    <div className="flex flex-col flex-grow">
      <Title>Гиды</Title>
      <div className="flex flex-col items-center flex-1 gap-4">
        {organizationsData &&
          organizationsData.map((organization: TOrganization) => {
            const avatarUrl =
              organization.avatar?.formats?.medium?.url ||
              organization.avatar?.formats?.small?.url ||
              organization.avatar?.formats?.thumbnail?.url ||
              "https://cdn.icon-icons.com/icons2/2483/PNG/512/organization_icon_149905.png";

            return (
              <div className="max-w-[720px] w-full" key={organization.id}>
                <div className="flex gap-4 ">
                  <div className="avatar">
                    <div className="w-40 rounded ">
                      <img src={avatarUrl} className="bg-base-200" />
                    </div>
                  </div>
                  <div className="flex flex-col justify-between flex-grow sm:flex-row">
                    <div className="flex flex-col ">
                      <h3 className="text-xl fb">{organization.title}</h3>

                      <div className="mt-2">
                        {/* <WithIcon
                          Icon={<PhoneIcon className="w-6 text-primary" />}
                        >
                          {organization.phone && (
                            <a href={`tel:${organization.phone}`}>{organization.phone}</a>
                          )}
                        </WithIcon> */}
                        <WithIcon
                          Icon={<EmailIcon className="w-6 text-primary" />}
                        >
                          {organization.email && (
                            <a href={`mailto:${organization?.email}`}>
                              {organization.email}
                            </a>
                          )}
                        </WithIcon>
                      </div>
                    </div>
                    <Link
                      className="mt-2 btn btn-primary"
                      to={"/organizations/" + organization.id}
                    >
                      Подробнее
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
