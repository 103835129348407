import { Account } from "@/widgets/account";
import { MyGuideLayout } from "@/widgets/layouts/ui/my-guide-layout";
import { MapWithPlaces } from "@/widgets/map";
import { useAppSelector } from "@/shared/model/hooks";
import { selectIsAuthorized } from "@/entities/session";
import { Sidebar } from "./sidebar";
import { ErrorBoundary } from "@/widgets/layouts";
import { useWindowDimensions } from "@/shared/hooks";
import { MobileActionsBar } from "./mobile-actions-bar";
import { ModalLogin } from "@/features/auth";
import { useParams } from "react-router-dom";

export const MyGuide: React.FC = () => {
  const isAuth = useAppSelector(selectIsAuthorized);
  const { width: windowWith } = useWindowDimensions();
  const isDesktop = windowWith >= 1024;
  const { isShowReadyRoutes } = useParams();

  const isShowLogin = !isAuth && isShowReadyRoutes === "ready-routes";

  return (
    <MyGuideLayout Account={<Account />}>
      <ErrorBoundary>
        {isShowLogin && (
          <ModalLogin
            defaultShowState={true}
            subtitle="Чтобы добавлять места в избранное, необходимо авторизоваться"
          />
        )}
        <div className="relative lg:items-start flex-1 flex  lg:flex-row flex-col">
          {isAuth && isDesktop && <Sidebar />}
          <div className="flex flex-grow">
            <MapWithPlaces />
          </div>
          {isAuth && !isDesktop && <MobileActionsBar />}
        </div>
      </ErrorBoundary>
    </MyGuideLayout>
  );
};
