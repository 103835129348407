import ButtonSearch from "@/shared/ui/button-search";
import { Link } from "react-router-dom";
import { useAfishaSearch } from "../hooks/use-afisha-search";

const AfishaSearch = () => {
  const { selectedDate, handleDateChange, nameEvent, handleNameEvent } =
    useAfishaSearch();
  return (
    <div className="flex flex-col gap-4 md:flex-row  w-full">
      <input
        type="text"
        placeholder="Найти мероприятие"
        className="input input-bordered flex-grow"
        onChange={handleNameEvent}
      />
      <label className="input input-bordered flex items-center gap-2">
        <input
          type="date"
          className="grow"
          placeholder="Найти дату"
          onChange={handleDateChange}
        />
      </label>
      <Link
        to={`/events${
          selectedDate && nameEvent
            ? `?date=${selectedDate}&nameEvent=${nameEvent}`
            : selectedDate
            ? `?date=${selectedDate}`
            : nameEvent
            ? `?nameEvent=${nameEvent}`
            : ""
        }`}
      >
        <ButtonSearch className="bg-primary text-white w-full">
          Найти
        </ButtonSearch>
      </Link>
    </div>
  );
};
export default AfishaSearch;
