import { Container } from "@/shared/ui";
import GooglePlayIcon from "@/shared/assets/icons/google-play-icon.svg?react";
import journeyLine from "@/shared/assets/journey-line.png";
import mobileAppImg from "@/shared/assets/mobile-app-phone-1.png";
import mobileAppImgTwo from "@/shared/assets/mobile-app-phone-2.png";

const MobileAppBanner = () => {
  return (
    <Container>
      <div
        className="w-full h-700 flex flex-col md:justify-between bg-base-200 my-10 px-5 py-11 gap-0 relative overflow-hidden rounded-2xl"
        id="mobile-app"
      >
        <div className="">
          <h1 className="text-2xl font-bold my-4">Твой ГИД в кармане </h1>
          <p className="text-sm md:w-1/2">
            Создайте свой маршрут, выбирая интересные точки и мероприятия.
            Слушайте интересные истории о местах, которые вы посещаете, подборка
            лучших ресторанов, кафе и магазинов рядом с вами. Поиск и навигация
            по интересным местам с GPS-навигацией
          </p>
        </div>

        <div
          className="btn shrink-0 bg-white flex w-fit h-auto py-2 px-3 
        mt-6 z-10 md:mb-16"
        >
          <GooglePlayIcon />
          <a href="https://play.google.com/store/apps/details?id=ru.elistory.elistory&pli=1">
            <div className="text-left">
              <p className="text-sm font-light">Доступно в</p>
              <h1 className="text-lg">Google Play</h1>
            </div>
          </a>
        </div>
        <img
          src={journeyLine}
          alt=""
          className="absolute left-0 -bottom-1/4 w-1/2 h-full object-contain object-left hidden md:block"
        />
        <img
          src={mobileAppImg}
          alt=""
          className="absolute w-40 bottom-5 left-3 xl:right-96 md:right-60 md:left-auto md:bottom-36 xl:h-4/5 xl:w-auto xl:bottom-10"
        />
        <img
          src={mobileAppImgTwo}
          alt=""
          className="absolute w-40 bottom-10 right-3 xl:w-auto xl:h-4/5 md:right-10 md:bottom-48 xl:right-20 xl:bottom-24"
        />
      </div>
    </Container>
  );
};
export default MobileAppBanner;
