import React from "react";

interface Props {
  imageSrc?: string;
  altText?: string;
  children?: React.ReactNode;
}

const ArticleCard: React.FC<Props> = ({ imageSrc, altText, children }) => {
  return (
    <div className="card card-compact w-full bg-base-100 mt-8 overflow-hidden">
      <figure>
        <img
          src={imageSrc}
          alt={altText}
          className="rounded-xl h-56 w-full object-cover"
        />
      </figure>
      <div className="card-body px-0">{children}</div>
    </div>
  );
};
export default ArticleCard;
