import { getImage } from "@/shared/lib";
import { TResImage } from "@/shared/model/types";
import { Card, ListGrid } from "@/shared/ui";

type TTour = {
  title: string;
  rating: number;
  id: number;
  preview_image: TResImage;
};

interface IProps {
  tours: TTour[];
}

export const ToursGrid: React.FC<IProps> = ({ tours }) => {
  return (
    <div className="flex-1">
      <ListGrid>
        {tours?.map((tour) => (
          <Card
            key={tour.id}
            preview_image_url={getImage(tour.preview_image)}
            redirectLink={"/tours/" + tour.id}
          >
            <h3 className="fb">{tour.title}</h3>
          </Card>
        ))}
      </ListGrid>
    </div>
  );
};
