import { useState } from "react";
import { Pagination } from "@/features/pagination";
import { Loading, Title } from "@/shared/ui";
import { ExcursionsGrid, useGetAllExcursionsQuery } from "@/entities/excursion";
import { selectIsOpenFromMobileApp } from "@/entities/session";
import { useAppSelector } from "@/shared/model/hooks";

export const ExcursionsList: React.FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const isOpenFromMobileApp = useAppSelector(selectIsOpenFromMobileApp);

  const { totalPages, excursionData, isLoading, isFetching } =
    useGetAllExcursionsQuery(
      {
        populate: "populate=preview_image",
        page: pageNumber,
        pageSize: 12,
      },
      {
        selectFromResult: (res) => ({
          totalPages: res?.data?.meta?.pagination?.pageCount,
          excursionData: res?.data?.data,
          ...res,
        }),
      }
    );

  if (isLoading || isFetching)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading size="lg" />
      </div>
    );

  return (
    <div className="flex flex-col flex-grow ">
      {!isOpenFromMobileApp && <Title>Экскурсии</Title>}
      <div className="flex-1">
        {excursionData && <ExcursionsGrid excursion={excursionData} />}
      </div>
      {totalPages > 0 && (
        <Pagination
          totalPages={totalPages}
          page={pageNumber}
          setPage={setPageNumber}
        />
      )}
    </div>
  );
};
