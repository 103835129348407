import { Link } from "react-router-dom";
import HomeIcon from "@/shared/assets/icons/home.svg?react";
import MyGuideIcon from "@/shared/assets/icons/my-guide.svg?react";
import EventsIcon from "@/shared/assets/icons/events.svg?react";
import MenuIcon from "@/shared/assets/icons/menu.svg?react";
import { PlacesMenu } from "./places-menu";
import { Details } from "@/shared/ui";

interface IProps {
  Account: React.ReactNode;
}

export const MobileNavigation: React.FC<IProps> = ({ Account }) => {
  return (
    <div className="btm-nav bg-base-200 text-[0.8em] md:text-base lg:hidden pb-8 pt-2 h-auto z-20">
      <Link to={"/home#plates"}>
        <HomeIcon /> <p>Главная</p>
      </Link>
      <Link to={"/events"}>
        <EventsIcon className="text-base-300" /> <p>Афиша</p>
      </Link>
      <Link to={"/my-guide"}>
        <MyGuideIcon /> <p>Мой гид</p>
      </Link>
      <Details className="dropdown-top dropdown-end mt-1">
        <summary className="relative flex flex-col items-center justify-center w-full h-full gap-2 border-current cursor-pointer">
          <MenuIcon /> <p>Меню</p>
        </summary>
        <PlacesMenu className="p-2 shadow menu dropdown-content z-[1] bg-base-200 rounded-box" />
      </Details>
      <span>
        {Account} <p>Профиль</p>
      </span>
    </div>
  );
};
