import { useFavoritePlaces, useTripPlaces } from "@/entities/user";
import { TPlace } from "@/shared/model/types";
import TrashIcon from "@/shared/assets/icons/trash.svg?react";
import { FavoriteButton } from "@/features/favorite-place";
import { ToggleTripPlacesButton } from "@/features/trip-place";
import { Dropdown, Price } from "@/shared/ui";
import { LockUnlock, getIconUrl } from "@/entities/places";
import { Link } from "react-router-dom";
import PlusIcon from "@/shared/assets/icons/plus.svg?react";

export const FavoritePlaces: React.FC = () => {
  const { favoritePlaces } = useFavoritePlaces();
  const { tripPlaces } = useTripPlaces();

  return (
    <div className="flex flex-col flex-grow gap-4 px-2 pt-2 overflow-y-auto">
      {favoritePlaces?.length === 0 && (
        <p className="mt-2">
          Добавляйте места в избранные, чтобы никогда не терять их из виду
        </p>
      )}
      {favoritePlaces &&
        favoritePlaces.map((place: TPlace) => {
          const iconUrl = getIconUrl(place.type, place.icon);

          const color = place?.type?.color || "#ffffff";
          return (
            <div key={place.id} className="space-y-1 rounded-xl bg-base-200">
              <div className="flex gap-2 ">
                <img
                  src={iconUrl}
                  style={{ backgroundColor: color }}
                  className={`rounded-xl p-1 w-[40px] object-cover h-[40px] border`}
                />
                <div className="flex items-start justify-between w-full">
                  <div className="">
                    <p className="fb">{place.title}</p>
                    <Dropdown
                      content={
                        <div className="flex flex-col py-2">
                          <LockUnlock
                            working_hours={place.working_hours}
                            working_hours_weekends={
                              place.working_hours_weekends
                            }
                            isOnlyText
                          />
                          <div className="flex">
                            <p>Средний чек:</p>
                            <Price
                              isApproximately
                              price={place.average_price}
                            />
                          </div>
                          <Link to={"/places/" + place.id} className="link">
                            Перейти на страницу
                          </Link>
                        </div>
                      }
                      textButton={<button className="link">скрыть</button>}
                      collapsedTextButton={
                        <button className="link">подробнее</button>
                      }
                    />
                  </div>
                  <div className="flex gap-2 mt-2">
                    {tripPlaces &&
                      !tripPlaces.some(
                        ({ id }: { id: number }) => place.id === id
                      ) && (
                        <ToggleTripPlacesButton
                          isTooltip
                          Content={<PlusIcon className="w-4" />}
                          placeId={place.id}
                          btnClasses="btn-primary btn-circle"
                          size="sm"
                        />
                      )}

                    <FavoriteButton
                      size="sm"
                      placeId={place.id}
                      CustomIcon={<TrashIcon className="w-4 text-primary" />}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
