import ButtonSearch from "@/shared/ui/button-search";
import { useHotelSearch } from "../hooks/use-hotel-search";

const HotelSearch = () => {
  const {
    count,
    startDate,
    endDate,
    searchTerm,
    handleSearchChange,
    handleStartDateChange,
    increment,
    decrement,
    handleEndDateChange,
  } = useHotelSearch();
  
  return (
    <div className="flex flex-col gap-4 md:flex-row  w-full">
      <input
        type="text"
        placeholder="Найти отель"
        className="input input-bordered w-full md:w-[45%]"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <div className="flex items-center gap-3">
        <p>От</p>
        <label className="input input-bordered flex items-center gap-2 flex-grow">
          <input
            type="date"
            className="grow"
            placeholder="Найти дату"
            value={startDate}
            onChange={handleStartDateChange}
          />
        </label>
      </div>
      <div className="flex items-center gap-3">
        <p>До</p>
        <label className="input input-bordered flex items-center gap-2 flex-grow">
          <input
            type="date"
            className="grow"
            placeholder="Найти дату"
            value={endDate}
            onChange={handleEndDateChange}
          />
        </label>
      </div>
      <div className="flex items-center">
        <div className="join flex w-full md:w-auto">
          <button
            className="join-item btn flex-grow md:flex-grow-0"
            onClick={decrement}
          >
            -
          </button>
          <button className="join-item btn flex-grow md:flex-grow-0 no-animation">
            Посетителей: {count}
          </button>
          <button
            className="join-item btn flex-grow md:flex-grow-0"
            onClick={increment}
          >
            +
          </button>
        </div>
      </div>
      <a
        href={`https://hotels.elistory.ru/ru/hotel/search.php?sd=${startDate}&ed=${endDate}&target_show=${searchTerm}&target=city_261&guests=${count}&client_show=WWW&client=3287`}
      >
        <ButtonSearch className="bg-primary text-white">Найти</ButtonSearch>
      </a>
    </div>
  );
};
export default HotelSearch;
