import { UseConditionsArticle } from "@/entities/static";
import { Background, Container } from "@/shared/ui";
import { ErrorBoundary } from "@/widgets/layouts";

export const UseConditions: React.FC = () => {
  return (
    <ErrorBoundary>
      <Background type={3} />
      <Container className="flex justify-center h-full">
        <UseConditionsArticle />
      </Container>
    </ErrorBoundary>
  );
};
