import { getNext120Days } from "@/shared/lib";
import { HorizontalScroll } from "@/shared/ui";
import { DateTime } from "luxon";
import qs from "qs";
import { useEffect, useMemo, useState } from "react";

interface IProps {
  className?: string;
  setFilterByDate: (value: string) => void;
  defaultDate: string | null;
}

const WEEK_DAYS = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
const MONTHS = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const FilterByDate: React.FC<IProps> = ({
  className = "",
  setFilterByDate,
  defaultDate,
}) => {
  const [selectedDay, setSelectedDay] = useState<DateTime>();

  useEffect(() => {
    if (defaultDate) {
      const day = DateTime.fromFormat(defaultDate, "yyyy-MM-dd");
      selectDateHandler(day);
    }
  }, [defaultDate]);

  function selectDateHandler(day: DateTime) {
    if (day?.toSeconds() === selectedDay?.toSeconds()) {
      setSelectedDay(undefined);
    } else if (day) {
      const query = qs.stringify(
        {
          filters: {
            schedules: {
              date: { $eq: day.toISODate() },
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );
      setFilterByDate(query);
      setSelectedDay(day);
    }
  }

  const dates = useMemo(() => getNext120Days(), []);
  const month = Object.keys(dates);
  const currentMonth = DateTime.local().month;
  const currentMonthIndex = month.indexOf(currentMonth.toString());

  const sortedMonth = month
    .slice(currentMonthIndex)
    .concat(month.slice(0, currentMonthIndex));

  return (
    <HorizontalScroll>
      <div className={className + " flex gap-8 mt-6"}>
        {sortedMonth.map((mountIndex) => (
          <ul key={mountIndex} className="relative flex gap-2 ">
            <span className="absolute left-0 ml-2 text-gray-500 bottom-full">
              {MONTHS[Number(mountIndex) - 1]}
            </span>
            {dates[Number(mountIndex)].days.map((day) => (
              <li
                key={day.toSeconds()}
                onClick={() => {
                  selectDateHandler(day);
                }}
                className={`flex flex-col items-center gap-1 py-1 cursor-pointer rounded-2xl select-none w-[2.5rem] ${
                  selectedDay?.toISODate() === day.toISODate()
                    ? "bg-primary"
                    : "hover:bg-base-200"
                }`}
              >
                <span
                  className={`text-2xl fb ${
                    day.weekday > 5 ? "text-error" : "text-gray-500"
                  }`}
                >
                  {day.day}
                </span>
                <span
                  className={`text-xs ${
                    day.weekday > 5 ? "text-error" : "text-gray-500"
                  }`}
                >
                  {WEEK_DAYS[day.weekday - 1]}
                </span>
              </li>
            ))}
          </ul>
        ))}
      </div>
    </HorizontalScroll>
  );
};
