import FreeIcon from "@/shared/assets/icons/free.svg?react";

interface IProps {
  isApproximately?: boolean;
  price?: number;
  className?: string;
  isFreeIcon?: boolean;
  freeIconSize?: number;
}
export const Price: React.FC<IProps> = ({
  isApproximately = false,
  price,
  className = "",
  isFreeIcon = false,
  freeIconSize = 6,
}) => {
  if (price === null || price === undefined) return <></>;
  if (Number(price) === 0)
    return (
      <>
        {isFreeIcon ? (
          <FreeIcon className={`w-${freeIconSize} text-success`} />
        ) : (
          <p className="text-success">Бесплатно</p>
        )}
      </>
    );
  return (
    <span className={`inline-block ${className}`}>
      <span className="flex">
        {isApproximately && (
          <span
            style={{
              fontFamily: "-apple-system,BlinkMacSystemFont,Segoe UI",
            }}
            className="-mt-[0.2rem] text-xl"
          >
            ~
          </span>
        )}
        <span>{price} руб.</span>
      </span>
    </span>
  );
};
