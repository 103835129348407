import { PlacesGrid } from "@/entities/places";
import { useFavoritePlaces } from "@/entities/user";
import { FavoriteButton } from "@/features/favorite-place";
import { Loading } from "@/shared/ui";

export const FavoritePlaces: React.FC = () => {
  const { favoritePlaces, isLoading, error } = useFavoritePlaces();

  if (isLoading) return <Loading size="lg" />;
  if (error) {
    console.error(error);
    return (
      <p className="text-center text-error">
        Произошла непредвиденная ошибка, пожалуйста повторите попытку позже
      </p>
    );
  }

  if (favoritePlaces?.length === 0)
    return <p>У вас пока нет избранных мест. </p>;

  return <PlacesGrid FavoriteButton={FavoriteButton} places={favoritePlaces} />;
};
