import { useAppSelector } from "@/shared/model/hooks";
import { selectUserId } from "..";
import {
  useGetFavoritePlacesQuery,
  useUpdateFavoritePlacesMutation,
} from "../api/user-api";
import { TPlace } from "@/shared/model/types";
import { selectIsAuthorized } from "@/entities/session";

export const useFavoritePlaces = () => {
  const [updateFavoritePlaces] = useUpdateFavoritePlacesMutation();
  const userId = useAppSelector(selectUserId);
  const isAuth = useAppSelector(selectIsAuthorized);

  const { favoritePlaces, isLoading, error } = useGetFavoritePlacesQuery("", {
    selectFromResult(res) {
      return {
        favoritePlaces: res?.data?.favorite_places,
        ...res,
      };
    },
    skip: !userId,
  });

  async function toggleFavoritePlace(placeId: number) {
    if (userId) {
      const prevPlacesIds = favoritePlaces?.map((place: TPlace) => place.id);
      const newPlacesIds = prevPlacesIds.includes(placeId)
        ? prevPlacesIds?.filter((id: number) => id !== placeId)
        : [placeId, ...prevPlacesIds];
      await updateFavoritePlaces({ id: userId, favorite_places: newPlacesIds });
    }
  }

  function checkingIsFavoritePlace(placeId: number): boolean {
    if (!isAuth) return false;

    if (
      favoritePlaces &&
      favoritePlaces.some((place: TPlace) => place.id === placeId)
    )
      return true;
      
    return false;
  }

  return {
    checkingIsFavoritePlace,
    toggleFavoritePlace,
    favoritePlaces,
    isLoading,
    error,
  };
};
