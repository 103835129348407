import { TTour, TTourSchedule } from "@/shared/model/types";
import { Counter, Loading, Modal } from "@/shared/ui";
import { useState } from "react";
import { IFormValues } from "../model/types";
import { useForm } from "react-hook-form";
import { ApplicationFormInputs } from "./application-form-inputs";
import { useJoinTour } from "../hooks/use-join-tour";
import { SelectTourSchedule } from "./select-tour-schedule";

interface IProps {
  tourData: TTour;
  btnText?: string;
}

const joinEventButtonCSSClasses = "text-lg btn btn-primary font-[400] w-full";

export const JoinTour: React.FC<IProps> = ({
  tourData,
  btnText = "Записаться на мероприятие",
}) => {
  return (
    <Modal className="max-w-3xl" content={<ModalContent tourData={tourData} />}>
      <button className={joinEventButtonCSSClasses}>{btnText}</button>
    </Modal>
  );
};

const ModalContent: React.FC<IProps> = ({ tourData }) => {
  const [selectedSchedule, setSelectedSchedule] = useState<TTourSchedule>();
  const { createApplicationTour, isSuccessCreatedApplication } = useJoinTour();
  const [customersCount, setCustomersCount] = useState(1);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<IFormValues>();

  async function onSubmit(formData: IFormValues) {
    if (selectedSchedule) {
      const { id: scheduleId } = selectedSchedule;
      const totalPrice = tourData.price * customersCount;
      const { name, phone, email } = formData;
      const data = {
        name,
        tour_id: tourData.id,
        phone,
        email,
        total_price: totalPrice,
        customers_count: customersCount,
        tour_schedule: scheduleId,
      };
      await createApplicationTour(data);
    }
  }

  if (isSuccessCreatedApplication)
    return (
      <>
        <div role="alert" className="mt-4 alert alert-success">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 stroke-current shrink-0"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>
            Заявка успешно создана! В скоре с вами свяжется организатор тура для
            подтверждения и оплаты поездки.
          </span>
        </div>
        <form method="dialog" className="mt-4">
          <button className="w-full btn">Закрыть</button>
        </form>
      </>
    );

  return (
    <form
      className="flex flex-col gap-2 mt-4 text-base"
      onSubmit={handleSubmit(onSubmit)}
    >
      <SelectTourSchedule
        price={tourData.price}
        onChangeSchedule={setSelectedSchedule}
        schedulesData={tourData.tour_schedules}
      >
        {selectedSchedule && (
          <>
            <ApplicationFormInputs
              control={control}
              formRegister={register}
              fieldErrors={errors}
            />
            <Counter
              className="mt-2"
              value={customersCount}
              onChangeCount={setCustomersCount}
            />
          </>
        )}
      </SelectTourSchedule>
      <button
        type="submit"
        className="submit-button"
        disabled={isSubmitting || !selectedSchedule}
      >
        {isSubmitting ? (
          <Loading size="sm" />
        ) : selectedSchedule ? (
          "Записаться (" + tourData?.price * customersCount + " руб.)"
        ) : (
          "Выберете дату и время экскурсии"
        )}
      </button>
    </form>
  );
};
