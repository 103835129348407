import { useEffect, useState } from "react";
import { eventTypes } from "../model/event-types";
import qs from "qs";

interface IProps {
  setFilterByTypes: (filterByTags: string) => void;
  className?: string;
}

export const FilterByTypes: React.FC<IProps> = ({
  setFilterByTypes,
  className = "",
}) => {
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

  useEffect(() => {
    if (selectedTypes.length === 0) {
      setFilterByTypes("");
    } else {
      setFilterByTypes(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTypes]);

  const filter = qs.stringify({
    filters: {
      $or: selectedTypes.map((type) => ({
        $or: [{ type }],
      })),
    },
  });

  function clearSelectedTags() {
    setSelectedTypes([]);
  }

  function handleToggleTag(type: string) {
    setSelectedTypes((prev) => {
      if (prev.includes(type)) {
        return prev.filter((item: string) => item !== type);
      }
      return [...prev, type];
    });
  }

  return (
    <div className={`flex gap-2 ${className} flex-wrap`}>
      <label className="space-x-2 cursor-pointer label bg-base-200 rounded-xl px-2">
        <span className="label-text">Все</span>
        <input
          type="checkbox"
          onChange={clearSelectedTags}
          checked={selectedTypes.length === 0}
          className="checkbox checkbox-primary"
        />
      </label>
      {eventTypes?.map((type) => (
        <label
          className="space-x-2 cursor-pointer label bg-base-200 rounded-xl px-2"
          key={type}
        >
          <span className="label-text">{type}</span>
          <input
            onChange={() => handleToggleTag(type)}
            type="checkbox"
            checked={selectedTypes?.some(
              (selectedType) => selectedType === type
            )}
            className="checkbox checkbox-primary"
          />
        </label>
      ))}
    </div>
  );
};
