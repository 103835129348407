import { Modal } from "@/shared/ui";
import { DownloadApp } from "./download-app";
import Logo from "@/shared/assets/logo.svg?react";

interface IProps {
  buttonText?: string;
  modalText?: string;
  className?: string;
  isIcon?: boolean;
}

export const DownloadAppModal: React.FC<IProps> = ({
  buttonText = "Elistory App",
  modalText,
  className = "",
  isIcon = true,
}) => {
  return (
    <Modal content={<DownloadApp text={modalText} />} className="max-w-2xl">
      <button className={"text-lg btn btn-primary " + className}>
        {isIcon && <Logo className="w-7" />}
        {buttonText}
      </button>
    </Modal>
  );
};
