import { Controller, useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask-format";
import { useState } from "react";
import { useCreateApplicationHotelMutation } from "../api/application-hotel-api";
import { Price } from "@/shared/ui";

interface IProps {
  id: number;
  price?: number;
  handleApplicationCreatedSuccess: () => void;
}

interface IFormValues {
  name: string;
  phone: string;
  email: string;
  customers_count: number;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}

export const ApplicationForm: React.FC<IProps> = ({
  id,
  price,
  handleApplicationCreatedSuccess,
}) => {
  const [error, setError] = useState<string>("");
  const [createApplicationHotel] = useCreateApplicationHotelMutation();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    watch,
  } = useForm<IFormValues>();
  const customers_count = watch("customers_count");

  async function onSubmit(formData: IFormValues) {
    const approximate_total_price = price
      ? price * formData.customers_count
      : null;
    const {
      name,
      phone,
      email,
      customers_count,
      startDate,
      endDate,
      endTime,
      startTime,
    } = formData;
    const data = {
      name,
      place_id: id,
      phone,
      email,
      approximate_total_price,
      customers_count,
      start_date: startDate,
      end_date: endDate,
      start_time: startTime + ":00",
      end_time: endTime + ":00",
    };

    try {
      await createApplicationHotel({ data }).unwrap();
      handleApplicationCreatedSuccess();
    } catch (error) {
      if (error && typeof error === "string") setError(error);
    }
  }

  return (
    <form
      className="flex flex-col gap-2 mt-4 text-base"
      onSubmit={handleSubmit(onSubmit)}
    >
      {errors.name && (
        <label className="text-error">{errors.name.message}</label>
      )}
      <input
        type="text"
        placeholder="ФИО"
        className={`input input-bordered ${errors.name ? "input-error" : ""}`}
        {...register("name", {
          required: "Введите имя фамилию и отчество",
        })}
      />

      {errors.phone && (
        <label className="text-error">{errors.phone.message}</label>
      )}
      <Controller
        name="phone"
        control={control}
        defaultValue=""
        rules={{
          required: "Введите номер телефона",
          validate: (value) => {
            if (value.includes("_")) return "Введите номер телефона";
          },
        }}
        render={({ field }) => (
          <ReactInputMask
            {...field}
            className={`input input-bordered ${
              errors.phone ? "input-error" : ""
            }`}
            mask="+7 (999) 999-99-99"
            placeholder="Телефон"
          />
        )}
      />

      {errors.email && (
        <label className="text-error">{errors.email.message}</label>
      )}
      <input
        type="text"
        placeholder="Почта"
        className={`input input-bordered ${errors.email ? "input-error" : ""}`}
        {...register("email", {
          required: "Введите Email",
          pattern: {
            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            message: "Email не верно указан",
          },
        })}
      />

      {errors.customers_count && (
        <label className="text-error">{errors.customers_count.message}</label>
      )}
      <input
        type="number"
        placeholder="Количество человек"
        className={`input input-bordered ${
          errors.customers_count ? "input-error" : ""
        }`}
        {...register("customers_count", {
          required: "Заполните поле",
          validate: (value) => {
            if (value < 1) return "Введите целое положительное число";
            if (value.toString().includes("e")) return "Введите число";
            if (value.toString().includes(".")) return "Введите целое число";
          },
        })}
      />
      <p>въезд</p>
      <div className="flex justify-between gap-2">
        <div className="flex-1">
          {errors.startDate && (
            <label className="text-error">{errors.startDate.message}</label>
          )}
          <input
            type="date"
            placeholder="Дата въезда"
            className={`input input-bordered  w-full ${
              errors.startDate ? "input-error" : ""
            }`}
            {...register("startDate", {
              required: "Заполните поле",
            })}
          />
        </div>
        <div className="flex-1">
          {errors.startTime && (
            <label className="text-error">{errors.startTime.message}</label>
          )}
          <input
            type="time"
            placeholder="Время въезда"
            className={`input w-full input-bordered ${
              errors.startTime ? "input-error" : ""
            }`}
            {...register("startTime", {
              required: "Заполните поле",
            })}
          />
        </div>
      </div>
      <p>выезд</p>
      <div className="flex justify-between gap-2">
        <div className="flex-1">
          {errors.endDate && (
            <label className="text-error">{errors.endDate.message}</label>
          )}
          <input
            type="date"
            placeholder="Дата въезда"
            className={`input input-bordered w-full ${
              errors.endDate ? "input-error" : ""
            }`}
            {...register("endDate", {
              required: "Заполните поле",
            })}
          />
        </div>
        <div className="flex-1">
          {errors.endTime && (
            <label className="text-error">{errors.endTime.message}</label>
          )}
          <input
            type="time"
            placeholder="Время выезда"
            className={`input w-full input-bordered ${
              errors.startTime ? "input-error" : ""
            }`}
            {...register("endTime", {
              required: "Заполните поле",
            })}
          />
        </div>
      </div>

      {price && price !== 0 && (
        <div className="flex">
          Цена на человека:
          <Price isApproximately price={price} />
        </div>
      )}
      {price && (
        <div className="flex">
          Общая стоимость:
          <Price isApproximately price={price * customers_count || price} />
        </div>
      )}

      {error && <label className="text-error">{error}</label>}

      <button type="submit" className="submit-button" disabled={isSubmitting}>
        {isSubmitting ? (
          <span className="loading loading-spinner loading-sm"></span>
        ) : (
          "Записаться"
        )}
      </button>
    </form>
  );
};
