import { useFilterByTag } from "../hooks/use-filter-by-tag";

interface IProps {
  setFilterByTags: (filterByTags: string) => void;
  tags: { id: number; title: string }[];
  className?: string;
}

export const FilterByTag: React.FC<IProps> = ({
  setFilterByTags,
  tags,
  className = "",
}) => {
  const { clearSelectedTags, selectedTags, handleToggleTag } =
    useFilterByTag(setFilterByTags);

  return (
    <div className={`flex gap-2 ${className} flex-wrap`}>
      <label className="space-x-2 cursor-pointer label bg-base-200 rounded-xl px-2">
        <span className="label-text">Все</span>
        <input
          type="checkbox"
          onChange={clearSelectedTags}
          checked={selectedTags.length === 0}
          className="checkbox checkbox-primary"
        />
      </label>
      {tags?.map((tag) => (
        <label
          className="space-x-2 cursor-pointer label bg-base-200 rounded-xl px-2"
          key={tag.id}
        >
          <span className="label-text">{tag.title}</span>
          <input
            onChange={() => handleToggleTag(tag.id)}
            type="checkbox"
            checked={selectedTags?.some((id) => id === tag.id)}
            className="checkbox checkbox-primary"
          />
        </label>
      ))}
    </div>
  );
};
