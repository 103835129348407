import { RegistrationForm } from "@/features/auth";
import { Background, Title } from "@/shared/ui";
import { ErrorBoundary } from "@/widgets/layouts";
import { Link } from "react-router-dom";

export const Registration: React.FC = () => {
  return (
    <ErrorBoundary>
      <div className="flex flex-col items-center justify-center flex-1">
        <Background type={2} />
        <Title>регистрация личного кабинета</Title>
        <div className="max-w-[500px] w-full px-4">
          <RegistrationForm />

          <div className="flex justify-between mt-2">
            <Link to={"/auth/login"} className="w-full text-center link">
              Уже есть аккаунт
            </Link>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
