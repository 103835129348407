import {
  selectUserId,
  useGetUserHomeQuery,
  useUpdateHomeMutation,
} from "@/entities/user";
import { useAppSelector } from "@/shared/model/hooks";

interface IProps {
  children?: React.ReactNode;
  placeId: number;
}
export const SelectHome: React.FC<IProps> = ({
  children = "Я остановился здесь",
  placeId,
}) => {
  const userId = useAppSelector(selectUserId);

  const { home } = useGetUserHomeQuery("", {
    selectFromResult: (res) => ({ home: res?.data?.home }),
  });

  const [updateUserHome] = useUpdateHomeMutation();

  async function selectHome() {
    if (userId && placeId) {
      await updateUserHome({
        id: userId,
        placeId,
      });
    }
  }

  async function cleanHome() {
    if (userId) {
      await updateUserHome({
        id: userId,
        placeId: null,
      });
    }
  }

  const isUserHome = home?.id === placeId;

  if (isUserHome)
    return (
      <button
        onClick={cleanHome}
        className="mt-4 btn btn-outline btn-sm btn-error"
      >
        Отменить выбор
      </button>
    );

  return (
    <button
      onClick={selectHome}
      className="mt-4 btn btn-outline btn-sm btn-primary"
    >
      {children}
    </button>
  );
};
