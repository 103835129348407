import { TResImage } from "../model/types";

export function getImage(image: TResImage) {
  return (
    image?.formats?.medium?.url ||
    image?.formats?.small?.url ||
    image?.formats?.thumbnail?.url ||
    image?.url
  );
}
