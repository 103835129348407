import { baseApi } from "@/shared/api";
import { TQuery } from "../model/types";
import { ARTICLES_TAG } from "@/shared/api/tags";

export const articlesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllArticles: build.query({
      query: ({
        sort = "",
        fields = "",
        populate = "",
        filter = "",
        page = 1,
        pageSize = 12,
      }: TQuery) => ({
        url: `/articles?${populate}&${sort}&${fields}&${filter}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
        method: "GET",
      }),
      providesTags: [ARTICLES_TAG],
    }),
    getArticleById: build.query({
      query: ({ id }: { id: number }) => ({
        url: `/articles/${id}?populate=blocks.images,blocks.block_links,article_links`,
        method: "GET",
      }),
    }),
    updateViewCounter: build.mutation({
      query: ({ id, count }: { id: number; count: number }) => ({
        url: `/articles/${id}`,
        method: "PUT",
        body: { data: { view_counter: count } },
      }),
      invalidatesTags: [ARTICLES_TAG],
    }),
  }),
});

export const {
  useGetAllArticlesQuery,
  useGetArticleByIdQuery,
  useUpdateViewCounterMutation,
} = articlesApi;
