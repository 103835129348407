import { useState } from "react";

interface ICity {
  id: string;
  name: string;
}

const cities: ICity[] = [
  { id: "ESL", name: "Элиста" },
  { id: "MOW", name: "Москва" },
];

export const useAirTicketsSearch = () => {
  const [fromValue, setFromValue] = useState<string>("Откуда");
  const [toValue, setToValue] = useState<string>("Куда");
  const [fromCityId, setFromCityId] = useState<string | null>(null);
  const [toCityId, setToCityId] = useState<string | null>(null);
  const [departureDate, setDepartureDate] = useState<string>("");
  const [returnDate, setReturnDate] = useState<string>("");
  const [passengers, setPassengers] = useState<number>(1);

  const swapValues = () => {
    setFromValue(toValue);
    setToValue(fromValue);
    setFromCityId(toCityId);
    setToCityId(fromCityId);
  };

  const handleFromChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCity = cities.find((city) => city.name === e.target.value);
    setFromValue(selectedCity ? selectedCity.name : "Откуда");
    setFromCityId(selectedCity ? selectedCity.id : null);
  };

  const handleToChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCity = cities.find((city) => city.name === e.target.value);
    setToValue(selectedCity ? selectedCity.name : "Куда");
    setToCityId(selectedCity ? selectedCity.id : null);
  };

  const handleDepartureDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value.replace(/-/g, ""); 
    setDepartureDate(date);
  };

  const handleReturnDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value.replace(/-/g, ""); 
    setReturnDate(date);
  };

  const decreasePassengers = () => {
    if (passengers > 1) {
      setPassengers(passengers - 1);
    }
  };

  const increasePassengers = () => {
    setPassengers(passengers + 1);
  };

  return {
    cities,
    fromValue,
    toValue,
    fromCityId,
    toCityId,
    departureDate,
    returnDate,
    passengers,
    swapValues,
    handleFromChange,
    handleToChange,
    handleDepartureDateChange,
    handleReturnDateChange,
    decreasePassengers,
    increasePassengers,
  };
};

