import { useParams } from "react-router-dom";
import { TImage, TResImage } from "@/shared/model/types";
import { BreadCrumbs, Loading } from "@/shared/ui";
import { TourDetails, useGetTourByIdQuery } from "@/entities/tours";
import { useMemo } from "react";
import { getCurrentSchedules } from "@/shared/lib";
import { JoinTour } from "@/features/join-tour";
import { selectIsOpenFromMobileApp } from "@/entities/session";
import { useAppSelector } from "@/shared/model/hooks";

export const TourInfo: React.FC = () => {
  const { id } = useParams();
  const isOpenFromMobileApp = useAppSelector(selectIsOpenFromMobileApp);

  const { tourData, isLoading, images, isFetching } = useGetTourByIdQuery(
    { id: Number(id) },
    {
      selectFromResult: (res) => ({
        tourData: res?.data?.data,
        images: res?.data?.data?.images?.map(
          (image: TResImage): TImage => ({
            id: image?.id,
            url:
              image?.formats?.medium?.url ||
              image?.formats?.small?.url ||
              image?.formats?.thumbnail?.url,
          })
        ),
        ...res,
      }),
      skip: !id,
    }
  );

  const currentSchedule = useMemo(() => {
    if (tourData && tourData.tour_schedules) {
      return getCurrentSchedules(tourData.tour_schedules);
    }
  }, [tourData]);

  if (isLoading || isFetching)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading size="lg" />
      </div>
    );

  return (
    <div>
      {!isOpenFromMobileApp && <BreadCrumbs />}
      {tourData && (
        <TourDetails images={images} tourData={tourData}>
          {currentSchedule ? (
            <JoinTour
              tourData={tourData}
              btnText={"Стоимость: " + tourData?.price + " руб"}
            />
          ) : (
            <button className="w-full btn text-lg font-[400]" disabled>
              Запись пока не доступна
            </button>
          )}
        </TourDetails>
      )}
    </div>
  );
};
