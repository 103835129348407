import { createSlice } from "@reduxjs/toolkit";
import { routeApi } from "../api/route-api";

type TRouteData = { distance: number | null; duration: number | null };

interface IRouteSliceState {
  methodMovement: "car" | "foot";
  carRouteData: TRouteData;
  footRouteData: TRouteData;
}

const initialStates: IRouteSliceState = {
  methodMovement: "foot",
  carRouteData: { distance: null, duration: null },
  footRouteData: { distance: null, duration: null },
};

export const routeSlice = createSlice({
  name: "route",
  initialState: initialStates,
  reducers: {
    changeMethodMovement: (state, { payload }) => {
      state.methodMovement = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      routeApi.endpoints.getRouteByPlacesIds.matchFulfilled,
      (state: IRouteSliceState, { payload }) => {
        if (payload.distance && payload.duration) {
          if (state.methodMovement === "car")
            state.carRouteData = {
              distance: payload.distance,
              duration: payload.duration,
            };
          else
            state.footRouteData = {
              distance: payload.distance,
              duration: payload.duration,
            };
        }
      }
    );
  },
});

export const selectMethodMovement = (state: RootState) =>
  state.route.methodMovement;
export const selectFootRouteData = (state: RootState) =>
  state.route.footRouteData;
export const selectCarRouteData = (state: RootState) =>
  state.route.carRouteData;

export const { changeMethodMovement } = routeSlice.actions;
