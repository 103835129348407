import { useGetAllEventsQuery } from "@/entities/events";
import { getCurrentDateISO } from "@/shared/lib";

interface PreviewImage {
  width: number;
  height: number;
  url: string;
}

interface Event {
  id: number;
  title: string;
  description: string;
  short_description: string | null;
  recommended: boolean;
  preview_image: PreviewImage;
}

export const useRecommendedEvents = () => {
  const currentDate = getCurrentDateISO();
  const { events } = useGetAllEventsQuery(
    {
      filter: `filters[$and][0][date_delition][$gt]=${currentDate}&filters[$and][1][recommended][$eq]=true`,
      populate: "populate=preview_image&sort=updatedAt:DESC",
      page: 1,
      pageSize: 3,
    },
    {
      selectFromResult: (res) => {
        return {
          events: res?.data?.data as Event[],
        };
      },
    }
  );

  return { events };
};
