import { selectUsername } from "@/entities/user";
import { formatDate, formatTime, isDateGreaterThanNow } from "../lib";
import { TTicket } from "../model/types";
import { useAppSelector } from "../model/hooks";
import { Link } from "react-router-dom";
import QrCodeIcon from "@/shared/assets/icons/qr-code.svg?react";
import { Modal } from ".";
import QRCode from "react-qr-code";

interface IProps {
  ticketData: TTicket;
}

export const Ticket: React.FC<IProps> = ({ ticketData }) => {
  const username = useAppSelector(selectUsername);
  if (!ticketData || !ticketData?.event) return <></>;
  
  const schedule = ticketData?.event?.schedules.find(
    (schedule) => schedule.id === ticketData.schedule_id
  );

  const isOpacity = schedule
    ? !isDateGreaterThanNow(schedule.date + "T" + schedule.time)
    : false;

  return (
    <div
      className={`max-w-[780px] rounded-2xl flex w-full bg-base-200 ${
        isOpacity ? "opacity-70" : ""
      }`}
    >
      <div className="w-1/3 border-r-2 border-black border-dotted">
        <h3 className="flex flex-col gap-2 px-4 py-2 mt-8 fb bg-primary sm:flex-row">
          <p>Дата:</p>
          {schedule && <p> {formatDate(schedule.date, true)}</p>}
        </h3>
        <div className="px-4 mt-2 mb-8 space-y-2 text-sm">
          <p>Название</p>
          <p>Имя</p>
          <p>{ticketData.seats.length > 1 ? "Ряды" : "Ряд"}</p>
          <p>{ticketData.seats.length > 1 ? "Места" : "Место"}</p>
          <p>{ticketData.persons_count > 1 ? "Персоны" : "Персона"}</p>
          <p>Цена</p>
          <p>Статус</p>
        </div>
      </div>

      <div className="flex-grow">
        <h3 className="flex justify-between px-4 py-2 mt-8 fb bg-primary ">
          {schedule && (
            <p className="flex flex-col gap-2 sm:flex-row">
              <span>Время: </span> <span>{formatTime(schedule.time)}</span>
            </p>
          )}
          {ticketData.is_used && <p>Использован</p>}
        </h3>
        <div className="flex justify-between px-4 mt-2 mb-8 text-sm">
          <div className="space-y-2 ">
            <Link to={"/events/" + ticketData.event.id} className="link">
              {ticketData.event.title}
            </Link>
            <p>{username}</p>
            <p className="flex -ml-2 divide-x-2">
              {ticketData.seats.length ? (
                ticketData.seats.map((seat) => (
                  <span
                    key={seat.id}
                    className="block w-8 text-center border-gray-400"
                  >
                    {seat.seat_number}{" "}
                  </span>
                ))
              ) : (
                <>-</>
              )}
            </p>
            <p className="flex -ml-2 divide-x-2">
              {ticketData.seats.length ? (
                ticketData.seats.map((seat) => (
                  <span
                    key={seat.id}
                    className="block w-8 text-center border-gray-400"
                  >
                    {seat.row}{" "}
                  </span>
                ))
              ) : (
                <>-</>
              )}
            </p>
            <p>{ticketData.persons_count}</p>
            <p>
              {ticketData.total_price !== 0
                ? ticketData.total_price + " руб"
                : "Бесплатно"}
            </p>
            <p>
              {ticketData.is_paid ? (
                "Оплачен"
              ) : (
                <a className="link" href={ticketData.linkPay}>
                  Ожидает оплаты
                </a>
              )}
            </p>
          </div>
          <Modal
            content={
              <QRCode
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={ticketData.id.toString()}
              />
            }
          >
            <div className="self-center cursor-pointer rounded-xl bg-primary">
              <QrCodeIcon className="min-h-20 max-h-32" />
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};
