import { Container } from "@/shared/ui";
import NotFoundIcon from "@/shared/assets/icons/not-found.svg?react";
import { Link } from "react-router-dom";

export const NotFoundPage: React.FC = () => {
  return (
    <Container className="flex flex-col items-center justify-center flex-1 gap-2">
      <h2>Не удалось найти указанную страницу.</h2>
      <p>Проверьте корректность url в адресной строке.</p>
      <NotFoundIcon className="w-12" />
      <Link to={"/home"} className="text-base btn btn-primary">
        На главную
      </Link>
    </Container>
  );
};
