/* eslint-disable react-hooks/exhaustive-deps */
import { getNow } from "@/shared/lib";
import { useEffect, useState } from "react";
import qs from "qs";

interface IProps {
  setFilterByWorkingTime: (filterByWorkingTime: string) => void;
}
export const FilterByWorkingTime: React.FC<IProps> = ({
  setFilterByWorkingTime,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    if (isChecked) {
      setFilterByWorkingTime(workingNowFilter);
    } else {
      setFilterByWorkingTime("");
    }
  }, [isChecked]);

  const now = getNow();
  const workingNowFilter = qs.stringify({
    filters: {
      $or: [
        {
          $and: [
            { working_hours: { start: { $lt: now } } },
            { working_hours: { end: { $gt: now } } },
          ],
        },
        {
          $and: [
            { working_hours: { start: "00:00:00" } },
            { working_hours: { end: "00:00:00" } },
          ],
        },
      ],
    },
  });

  return (
    <div className="flex items-center gap-2">
      <input
        onChange={() => setIsChecked((prev) => !prev)}
        type="checkbox"
        checked={isChecked}
        className="checkbox checkbox-warning"
      />
      <span className="label-text">Только открытые</span>
    </div>
  );
};
