import { useTickets } from "@/entities/user";
import { TTicket } from "@/shared/model/types";
import { Loading, Ticket } from "@/shared/ui";
import { useMemo } from "react";
import { Link } from "react-router-dom";

interface IProps {
  className?: string;
}

function sortByNearestSchedule(tickets: TTicket[]) {
  if (!tickets || tickets?.length === 0) return [];
  return tickets.sort((a, b) => {
    const aSchedule = a.event.schedules.find(
      (schedule) => schedule.id === a.schedule_id
    );
    const bSchedule = b.event.schedules.find(
      (schedule) => schedule.id === b.schedule_id
    );

    if (aSchedule && bSchedule) {
      const aDate = new Date(aSchedule.date + "T" + aSchedule.time);
      const bDate = new Date(bSchedule.date + "T" + bSchedule.time);

      return bDate.getTime() - aDate.getTime();
    }
    return 0;
  });
}

export const UserTickets: React.FC<IProps> = ({ className = "" }) => {
  const { userTickets, isLoading, error } = useTickets();
  const sortedTicketsAndFiltered = useMemo(
    () =>
      userTickets && userTickets.length > 0
        ? sortByNearestSchedule(
            userTickets.filter(
              (ticket: TTicket) => ticket.event && ticket.event.schedules
            )
          )
        : [],
    [userTickets]
  );

  if (isLoading) return <Loading size="lg" />;
  if (error) {
    console.error(error);
    return (
      <p className="text-center text-error">
        Произошла непредвидимая ошибка, пожалуйста повторите попытку позже
      </p>
    );
  }

  if (sortedTicketsAndFiltered.length === 0)
    return (
      <p>
        У вас пока нет билетов на мероприятия. Все актуальные события Вы можете
        увидеть{" "}
        <Link to={"/events"} className="link">
          по ссылке
        </Link>
      </p>
    );

  return (
    <div className={`w-full flex flex-col items-center gap-2 ${className}`}>
      {sortedTicketsAndFiltered?.map((ticket: TTicket) => (
        <Ticket key={ticket.id} ticketData={ticket} />
      ))}
    </div>
  );
};
