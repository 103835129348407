import { TImage } from "../model/types";
import { Slider } from "@/shared/ui";

interface IProps {
  children: React.ReactNode;
  images: TImage[];
  FavoriteButton?: JSX.Element;
  TypeBadge?: JSX.Element;
}
export const Hero: React.FC<IProps> = ({ children, images, TypeBadge }) => {
  return (
    <div className="flex flex-col gap-4 lg:flex-row">
      <div className="relative w-full mt-2 overflow-hidden lg:w-1/2 lg:mt-0 ">
        {TypeBadge && (
          <div className="absolute z-20 right-4 top-4">{TypeBadge}</div>
        )}
        <Slider
          images={images}
          classNames="block rounded-lg overflow-hidden aspect-[16/9] flex-1"
        />
      </div>
      <div className="flex w-full gap-2 lg:w-1/2">
        <div className="w-full mt-4 md:mt-0">{children}</div>
      </div>
    </div>
  );
};
