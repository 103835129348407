interface IProps {
  phone?: string;
  email?: string;
  site?: string;
  className?: string;
}
export const ContactInfo: React.FC<IProps> = ({
  phone,
  email,
  site,
  className,
}) => {
  if (!phone && !email && !site) return <></>;

  return (
    <div className={`inline-block p-4  rounded-xl bg-base-200 ${className}`}>
      <h4 className="text-xl fb">Контактная информация</h4>

      <ul className="mt-2 space-y-2 list-disc ml-7">
        {phone && (
          <li className="hover:link">
            <a href={"tel:" + phone}>{phone}</a>
          </li>
        )}
        {email && (
          <li className="hover:link">
            <a href={"mailto:" + email}>{email}</a>
          </li>
        )}
        {site && (
          <li className="hover:link">
            <a href={site} target="_blank">
              {site}
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};
