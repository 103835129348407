import khurul from "@/shared/assets/khurul.png";
import { SearchPanel } from "@/widgets/search-panel";
import RoadLine from "@/shared/assets/icons/road-line.svg?react";

const HomeBanner = () => {
  return (
    <div className="w-full xl:px-4 xl:py-4 relative">
      <div className="relative h-[85vh] rounded-b-2xl md:rounded-2xl md:h-[95vh] w-full flex flex-col justify-end overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-custom-blue-start via-custom-blue-middle to-custom-beige -z-50"></div>
        <img
          src={khurul}
          alt=""
          className="absolute object-cover h-[60vh] md:h-auto md:w-full md:translate-y-1/4 -z-30 rounded-b-xl md:rounded-2xl xl:mt-6  md:left-1/2 md:transform md:-translate-x-1/2"
        />

        <RoadLine className="w-full absolute top-0 md:top-44 -z-40 md:left-0 md:scale-125" />

        <div className="relative container w-full  h-full mx-auto">
          <h1 className="text-white md:w-1/2 text-2xl md:text-4xl xl:text-6xl absolute top-32 md:top-1/4 xl:top-1/4 left-4 md:left-10 z-0 w-2/3 font-bold">
            Полный <br /> каталог&nbsp;событий для&nbsp;твоего отдыха
            в&nbsp;Калмыкии!
          </h1>
        </div>
        <SearchPanel />
      </div>
    </div>
  );
};
export default HomeBanner;
