import { TWorking_hours } from "@/shared/model/types";
import { WithIcon } from "@/shared/ui";
import UnlockedIcon from "@/shared/assets/icons/unlocked.svg?react";
import LockedIcon from "@/shared/assets/icons/locked.svg?react";
import {
  formatTime,
  isWeekend,
  isWorking,
  isSunday,
  isFriday,
} from "@/shared/lib";

const midnight = "00:00:00";

interface IProps {
  working_hours: TWorking_hours;
  working_hours_weekends: TWorking_hours;
  isOnlyIcon?: boolean;
  isOnlyText?: boolean;
}
export const LockUnlock: React.FC<IProps> = ({
  working_hours,
  working_hours_weekends,
  isOnlyIcon = false,
  isOnlyText = false,
}) => {
  if (!working_hours_weekends.start || !working_hours_weekends.end)
    return <></>;

  if (
    working_hours_weekends.end === midnight &&
    working_hours_weekends.start === midnight &&
    working_hours.start === midnight &&
    working_hours.end === midnight
  ) {
    if (isOnlyIcon) return <UnlockedIcon className="w-6 text-success" />;
    else if (isOnlyText) return <>Работает круглосуточно.</>;
    return (
      <WithIcon Icon={<UnlockedIcon className="w-6 text-success" />}>
        Работает круглосуточно.
      </WithIcon>
    );
  }

  if (isOnlyIcon)
    return (
      <>
        {isWeekend() ? (
          isWorking(
            working_hours_weekends.start,
            working_hours_weekends.end
          ) ? (
            <UnlockedIcon className="w-6 text-success" />
          ) : (
            <LockedIcon className="w-6 text-error" />
          )
        ) : isWorking(working_hours.start, working_hours.end) ? (
          <UnlockedIcon className="w-6 text-success" />
        ) : (
          <LockedIcon className="w-6 text-error" />
        )}
      </>
    );

  if (isOnlyText)
    return (
      <>
        {isWeekend() ? (
          isWorking(
            working_hours_weekends.start,
            working_hours_weekends.end
          ) ? (
            <>Закроется в {formatTime(working_hours_weekends.end)}</>
          ) : (
            <>
              {isSunday() ? (
                <>Откроется в {formatTime(working_hours.start)}</>
              ) : (
                <>Откроется в {formatTime(working_hours_weekends.start)}</>
              )}
            </>
          )
        ) : isWorking(working_hours.start, working_hours.end) ? (
          <>Закроется в {formatTime(working_hours.end)}</>
        ) : (
          <>
            {isFriday() ? (
              <>Откроется в {formatTime(working_hours_weekends.start)}</>
            ) : (
              <>Откроется в {formatTime(working_hours.start)}</>
            )}
          </>
        )}
      </>
    );
  return (
    <>
      {isWeekend() ? (
        isWorking(working_hours_weekends.start, working_hours_weekends.end) ? (
          <WithIcon Icon={<UnlockedIcon className="w-6 text-success" />}>
            Закроется в {formatTime(working_hours_weekends.end)}
          </WithIcon>
        ) : (
          <WithIcon Icon={<LockedIcon className="w-6 text-error" />}>
            {isSunday() ? (
              <>Откроется в {formatTime(working_hours.start)}</>
            ) : (
              <>Откроется в {formatTime(working_hours_weekends.start)}</>
            )}
          </WithIcon>
        )
      ) : isWorking(working_hours.start, working_hours.end) ? (
        <WithIcon Icon={<UnlockedIcon className="w-6 text-success" />}>
          Закроется в {formatTime(working_hours.end)}
        </WithIcon>
      ) : (
        <WithIcon Icon={<LockedIcon className="w-6 text-error" />}>
          {isFriday() ? (
            <>Откроется в {formatTime(working_hours_weekends.start)}</>
          ) : (
            <>Откроется в {formatTime(working_hours.start)}</>
          )}
        </WithIcon>
      )}
    </>
  );
};
