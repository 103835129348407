import { useState } from "react";
import { Pagination } from "@/features/pagination";
import { Loading, Title } from "@/shared/ui";
import { ToursGrid, useGetAllToursQuery } from "@/entities/tours";
import { selectIsOpenFromMobileApp } from "@/entities/session";
import { useAppSelector } from "@/shared/model/hooks";

export const ToursList: React.FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const isOpenFromMobileApp = useAppSelector(selectIsOpenFromMobileApp);

  const { totalPages, tourData, isLoading, isFetching } = useGetAllToursQuery(
    {
      populate: "populate=preview_image",
      page: pageNumber,
      pageSize: 12,
    },
    {
      selectFromResult: (res) => ({
        totalPages: res?.data?.meta?.pagination?.pageCount,
        tourData: res?.data?.data,
        ...res,
      }),
    }
  );

  if (isLoading || isFetching)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading size="lg" />
      </div>
    );

  return (
    <div className="flex flex-col flex-grow ">
      {!isOpenFromMobileApp && <Title>Туры</Title>}
      <div className="flex-1">{tourData && <ToursGrid tours={tourData} />}</div>
      {totalPages > 0 && (
        <Pagination
          totalPages={totalPages}
          page={pageNumber}
          setPage={setPageNumber}
        />
      )}
    </div>
  );
};
