// import { getFirstRecordWithDateLessThanNow, formatDate } from "@/shared/lib";
import { getImage } from "@/shared/lib";
import { TEvent } from "@/shared/model/types";
import { Card, ListGrid, TypeBadge } from "@/shared/ui";
import { AlreadyTicket } from "@/widgets/events/ui/already-ticket";

interface IProps {
  events: TEvent[];
}

export const EventsGrid: React.FC<IProps> = ({ events }) => {
  return (
    <div className="flex-1">
      {events.length === 0 && (
        <p className="text-center">
          Подходящих событий не найдено
          <br />
          Попробуйте выбрать другой фильтр
        </p>
      )}

      <ListGrid>
        {events?.map((event) => {
          return (
            <Card
              key={event.id}
              preview_image_url={getImage(event.preview_image)}
              TypeBadge={<TypeBadge>{event?.type}</TypeBadge>}
              redirectLink={"/events/" + event.id}
            >
              <h3 className="fb">{event?.title}</h3>
              <div className="flex justify-between text-lg">
                <p>{event?.short_description}</p>
                {event?.schedules[0]?.price === 0 ? (
                  <p className="text-green-600">Бесплатный</p>
                ) : (
                  <p>Платный</p>
                )}
              </div>
              <AlreadyTicket eventId={event.id}>
                <p className="text-success">Вы зарегистрированы</p>
              </AlreadyTicket>
            </Card>
          );
        })}
      </ListGrid>
    </div>
  );
};
