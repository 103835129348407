import { selectIsAuthorized } from "@/entities/session";
import { useGetUserInfoQuery } from "@/entities/user/api/user-api";
import { AuthButton } from "@/features/auth";
import { LogoutButton } from "@/features/auth";
import { useAppSelector } from "@/shared/model/hooks";
import { Link } from "react-router-dom";
import userIcon from "@/shared/assets/icons/user.svg";

export const Account: React.FC = () => {
  const isAuth = useAppSelector(selectIsAuthorized);

  const { avatarUrl } = useGetUserInfoQuery("", {
    selectFromResult(res) {
      return {
        avatarUrl: res?.data?.avatar?.formats?.thumbnail?.url,
        username: res?.data?.username,
      };
    },
  });

  if (isAuth)
    return (
      <div className="dropdown dropdown-top dropdown-end lg:dropdown-bottom">
        <div tabIndex={0} className="avatar " role="button">
          <div className="w-[30px] lg:w-10 rounded-full">
            <img src={avatarUrl || userIcon} />
          </div>
        </div>

        <ul
          tabIndex={1}
          className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box "
        >
          <li>
            <Link to="/profile/me">Мои данные</Link>
          </li>
          <li>
            <Link to="/profile/tickets">Мои билеты</Link>
          </li>
          <li>
            <Link to="/profile/favorites">Избранные места</Link>
          </li>
          <li>
            <Link to="/policy">Политика конфиденциальности</Link>
          </li>
          <li>
            <Link to="/use-conditions">Условия использования</Link>
          </li>
          <li>
            <LogoutButton />
          </li>
        </ul>
      </div>
    );

  return <AuthButton />;
};
