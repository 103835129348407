import { useGetStaticArticlesQuery } from "../api/static-api";

export const useStaticArticles = () => {
  const { privacyPolicyData, useConditions, isLoading, isFetching } =
    useGetStaticArticlesQuery("", {
      selectFromResult: (res) => {
        return {
          privacyPolicyData: res?.data?.data?.privacy_policy,
          useConditions: res?.data?.data?.use_conditions_service,
          ...res,
        };
      },
    });
  return { privacyPolicyData, useConditions, isLoading, isFetching };
};
