import { Container } from "@/shared/ui";
import img1 from "@/shared/assets/useful-articles-1.png";
import img2 from "@/shared/assets/useful-articles-2.png";
import img3 from "@/shared/assets/useful-articles-3.png";
import img4 from "@/shared/assets/useful-articles-4.png";
import img5 from "@/shared/assets/useful-articles-5.png";
import { Link } from "react-router-dom";

const UsefulArticles = () => {
 
  return (
    <Container className="mt-10 mb-20">
      <h3 className="text-2xl font-semibold mb-4">Полезные статьи</h3>
      <div className="grid grid-cols-2 grid-rows-6 gap-2 w-full md:grid-rows-6 md:h-96 md:grid-cols-5 mt-10">
        <div className="col-span-1 row-span-3 md:row-span-6 relative ">
          <Link to={"/articles/49"}>
            <img
              src={img1}
              className="w-full h-full object-cover rounded-2xl "
              alt=""
            />
            <p className="absolute top-6 left-4 text-base md:text-2xl font-bold w-1/2">
              Как добраться из Москвы в Элисту
            </p>
          </Link>
        </div>

        <div className="col-span-1 row-span-3 row-start-4 md:row-start-auto relative md:order-last md:row-span-6">
          <Link to={"/articles/52"}>
            <img
              src={img5}
              className="w-full h-full object-cover rounded-2xl "
              alt=""
            />
            <p className="absolute top-6 left-4 text-base md:text-2xl font-bold w-1/2 text-custom-amber">
              Больше полезных статей
            </p>
          </Link>
        </div>

        <div className="col-span-1 row-span-2 col-start-2  md:row-span-5 md:row-start-2 relative">
          <Link to={"/articles/48"}>
            <img
              src={img2}
              className="w-full h-full object-cover rounded-2xl "
              alt=""
            />
            <p className="absolute top-6 left-4 text-base md:text-2xl font-bold w-1/2 text-white">
              Фестиваль тюльпанов
            </p>
          </Link>
        </div>
        <div className="col-span-1 row-span-2 col-start-2 row-start-3 md:row-start-4 md:col-start-3 md:row-span-3 relative">
          <Link to={"/articles/51"}>
            <img
              src={img3}
              className="w-full h-full object-cover rounded-2xl "
              alt=""
            />
            <p className="absolute top-6 left-4 text-base md:text-2xl font-bold w-1/2">
              Гид по интересным местам
            </p>
          </Link>
        </div>
        <div className="col-span-1 row-span-2 col-start-2 row-start-5 md:row-start-2 md:row-span-5 md:col-start-4  relative">
          <Link to={"/articles/50"}>
            <img
              src={img4}
              className="w-full h-full object-cover rounded-2xl "
              alt=""
            />
            <p className="absolute top-6 left-4 text-base md:text-2xl font-bold w-1/2">
              Фестиваль лотосов
            </p>
          </Link>
        </div>
      </div>
    </Container>
  );
};
export default UsefulArticles;
