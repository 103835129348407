import { baseApi } from "@/shared/api";

export const applicationHotelApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createApplicationHotel: build.mutation({
      query: (data) => ({
        url: "/applications-hotels",
        method: "POST",
        body: data,
      }),
      transformErrorResponse(res) {
        if (res.status !== 200)
          return "Не удалось создать заявку на заселение, пожалуйста попробуйте позднее.";
      },
    }),
  }),
});

export const { useCreateApplicationHotelMutation } = applicationHotelApi;
