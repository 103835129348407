import React from "react";
import { Header } from "./header";
import { MobileNavigation } from "./mobile-navigation";

interface IProps {
  children: React.ReactNode;
  Account: React.ReactNode;
}

export const MyGuideLayout: React.FC<IProps> = ({ children, Account }) => {
  return (
    <div className="w-full min-h-[100vh] flex flex-col">
      <Header Account={Account} />
      {children}
      <MobileNavigation Account={Account} />
    </div>
  );
};
