import { useRegistrationConfirmPhoneMutation } from "@/entities/session";
import { selectConfirmPhone } from "@/entities/user";
import { ConfirmPhoneForm } from "@/features/auth";
import { useAppSelector } from "@/shared/model/hooks";
import { Background, Title } from "@/shared/ui";
import { ErrorBoundary } from "@/widgets/layouts";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const RegistrationConfirmPhone: React.FC = () => {
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();
  const confirmationPhone = useAppSelector(selectConfirmPhone);

  const [confirmRegistrationFetch] = useRegistrationConfirmPhoneMutation();

  useEffect(() => {
    if (confirmationPhone === null) {
      navigate("/auth/registration");
    }
  }, [confirmationPhone]);

  async function onSubmit({ pincode }: { pincode: string }) {
    if (pincode) {
      try {
        const cleanedCode = pincode.replace(/\D/g, "");
        await confirmRegistrationFetch({
          phone: confirmationPhone,
          pincode: cleanedCode,
        }).unwrap();
        navigate("/welcome");
      } catch (error) {
        if (error && typeof error === "string") setError(error);
      }
    }
  }

  return (
    <ErrorBoundary>
      <div className="flex flex-col items-center justify-center flex-1">
        <Background type={2} />
        <Title>Подтверждение номера</Title>
        <div className="max-w-[500px] w-full px-4">
          <ConfirmPhoneForm onSubmit={onSubmit} error={error} />
        </div>
      </div>
    </ErrorBoundary>
  );
};
