import React, { useEffect, useRef, useState } from "react";

interface IProps {
  children: React.ReactNode;
  content: JSX.Element;
  className?: string;
  defaultShowState?: boolean;
}
export const Modal: React.FC<IProps> = ({
  children,
  content,
  className = "",
  defaultShowState = false,
}) => {
  const modalRef = useRef<HTMLDialogElement | null>(null);
  const [isShowModalContent, setIsShowModalContent] = useState(false);

  useEffect(() => {
    if (defaultShowState === true && modalRef.current) {
      modalRef.current.showModal();
      setIsShowModalContent(true);
    }
  }, [defaultShowState, modalRef]);

  function showModal() {
    if (modalRef.current) {
      modalRef.current.showModal();
      setIsShowModalContent(true);
    }
  }
  return (
    <>
      <div className="cursor-pointer " onClick={showModal}>
        {children}
      </div>
      <dialog ref={modalRef} className="modal">
        {isShowModalContent && (
          <div className={`modal-box ${className}`}>{content}</div>
        )}
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
};
