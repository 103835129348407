import { useState } from "react";
import { Pagination } from "@/features/pagination";
import { Loading, Title } from "@/shared/ui";
import { useGetAllArticlesQuery } from "@/entities/articles/api/articles-api";
import { ArticlesGrid } from "@/entities/articles";

export const ArticlesList: React.FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);

  const { totalPages, articlesData, isLoading, isFetching } =
    useGetAllArticlesQuery(
      {
        populate: "populate=preview_image",
        page: pageNumber,
        pageSize: 12,
        sort: "sort=publishedAt:DESC",
      },
      {
        selectFromResult: (res) => ({
          totalPages: res?.data?.meta?.pagination?.pageCount,
          articlesData: res?.data?.data,
          ...res,
        }),
      }
    );

  if (isLoading || isFetching)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading size="lg" />
      </div>
    );

  return (
    <div className="flex flex-col flex-grow ">
      <Title>Новости</Title>
      <div className="flex-1">
        {articlesData && <ArticlesGrid articles={articlesData} />}
      </div>
      {totalPages > 0 && (
        <Pagination
          totalPages={totalPages}
          page={pageNumber}
          setPage={setPageNumber}
        />
      )}
    </div>
  );
};
