import { Loading } from "@/shared/ui";
import { useStaticArticles } from "../hooks/use-static-articles";
import { InformationBlock } from "@/entities/articles/ui/information-block";
import { TInformationBlock } from "@/shared/model/types";

interface IProps {
  className?: string;
}
export const UseConditionsArticle: React.FC<IProps> = ({ className }) => {
  const { useConditions, isLoading, isFetching } = useStaticArticles();

  if (isLoading || isFetching)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading size="lg" />
      </div>
    );

  return (
    <div className={`${className}`}>
      <div className="flex flex-col gap-4 mt-4">
        <div className="flex flex-col justify-end gap-2 w-full">
          <h6 className="mb-2">Информация об организации</h6>
          <p className="text-gray-500 ">ИП Муниев А.М.</p>
          <p className="text-gray-500 ">ИНН: 081407563384</p>
          <p className="text-gray-500 ">ОГРНИП: 319081600009565A</p>
          <p className="text-gray-400 ">
            Почтовый адрес: 358000, республика Калмыкия, ул. Клыкова, 1а
          </p>
          <a className="text-gray-400 " href={`mailto:am@muniev.ru`}>
            am@muniev.ru
          </a>
        </div>
        {useConditions?.map((block: TInformationBlock) => (
          <div key={block.id}>
            <InformationBlock block={block} />
          </div>
        ))}
      </div>
    </div>
  );
};
