import { useCreateApplicationExcursionMutation } from "@/entities/excursion";

type TPropsCreateApplicationExcursion = {
  name: string;
  phone: string;
  email: string;
  total_price: number;
  customers_count: number;
  excursion_schedule: number;
  excursion_id: number;
};

export const useJoinExcursion = () => {
  const [
    createApplicationExcursionQuery,
    { isSuccess: isSuccessCreatedApplication },
  ] = useCreateApplicationExcursionMutation();

  async function createApplicationExcursion(
    data: TPropsCreateApplicationExcursion
  ) {
    try {
      await createApplicationExcursionQuery({ data }).unwrap();
    } catch (error) {
      console.error(error);
    }
  }

  return { createApplicationExcursion, isSuccessCreatedApplication };
};
