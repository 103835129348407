import { ChangeEvent, useState } from "react";

const useRestaurantsSearch = () => {
  const [currentKitchen, setCurrentKitchen] = useState<string>("");
  const [enCurrentKitchen, setEnCurrentKitchen] = useState<string>("");
  const [desiredRestaurant, setDesiredRestaurant] = useState<string>("");
  interface Kitchens {
    slagRu: string;
    slagEn: string;
  }
  const handleSearchRestaurant = (event: ChangeEvent<HTMLInputElement>) => {
    setDesiredRestaurant(event.target.value);
  };

  const kitchens: Kitchens[] = [
    {
      slagRu: "Вегетарианская",
      slagEn: "veganskaya-kuhnya",
    },
    {
      slagRu: "Грузинская",
      slagEn: "gruzinskaya-kuhnya",
    },
    {
      slagRu: "Европейская",
      slagEn: "evropejskaya-kuhnya",
    },
    {
      slagRu: "Кавказская",
      slagEn: "kavkazskaya-kuhnya",
    },
    {
      slagRu: "Калмыцкая",
      slagEn: "kalmyczkaya-kuhnya",
    },
    {
      slagRu: "Паназиатская",
      slagEn: "panaziatskaya-kuhnya",
    },
    {
      slagRu: "Русская",
      slagEn: "russkaya-kuhnya",
    },
  ];
  return {
    currentKitchen,
    setCurrentKitchen,
    enCurrentKitchen,
    setEnCurrentKitchen,
    desiredRestaurant,
    handleSearchRestaurant,
    kitchens,
  };
};
export default useRestaurantsSearch;
