import { Container } from "@/shared/ui";
import { useSearchPanel } from "../hooks/use-search-panel";

const SearchPanel = () => {
  const { selectedTab, isDesktop, setSelectedTab, tabList } = useSearchPanel();
  return (
    <Container className="relative">
      <div className="tabs mb-8 bg-blue-950 rounded-2xl">
        <div className="flex justify-between md:justify-start">
          {tabList.map((tab, index) => (
            <button
              key={tab.ariaLabel}
              className="btn no-animation text-white bg-transparent border-none"
              onClick={() => setSelectedTab(() => index)}
            >
              {isDesktop ? (
                <>
                  <span
                    className="ml-2 flex gap-2"
                    style={{
                      color: index === selectedTab ? "#EAA623" : "white",
                    }}
                  >
                    {tab.ariaLabel}
                  </span>
                  {tab.icon({ isActive: index === selectedTab })}
                </>
              ) : (
                tab.icon({ isActive: index === selectedTab })
              )}
            </button>
          ))}
        </div>
        <div className="w-full bg-white px-5 py-5 rounded-2xl">
          {tabList[selectedTab].component}
        </div>
      </div>
    </Container>
  );
};
export default SearchPanel;
