import { Title } from "@/shared/ui";
import { Link } from "react-router-dom";

export const Welcome = () => {
  return (
    <div className="flex flex-col items-center justify-center flex-1">
      <Title>Вы авторизированны</Title>
      <div className="flex justify-center gap-4 flex-col md:flex-row w-full p-4">
        <Link className="btn btn-outline " to={"/home#plates"}>
          Перейти на главную
        </Link>
        <Link className="btn btn-outline " to={"/my-guide"}>
          Создать свой маршрут
        </Link>
      </div>
    </div>
  );
};
