import { baseApi } from "@/shared/api";
import { TQuery } from "../model/types";

export const organizationsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllOrganizations: build.query({
      query: ({ populate, page = 1, pageSize = 999 }: TQuery) => ({
        url: `/organizations?${populate}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
        method: "GET",
      }),
    }),
    getOrganizationById: build.query({
      query: ({ id }: { id: number }) => ({
        url: `/organizations/${id}?populate=*`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAllOrganizationsQuery, useGetOrganizationByIdQuery } =
  organizationsApi;
