import { Link, useParams } from "react-router-dom";
import { TGuide, TImage, TResImage } from "@/shared/model/types";
import { BreadCrumbs, Loading } from "@/shared/ui";
import {
  ExcursionDetails,
  useGetExcursionByIdQuery,
} from "@/entities/excursion";
import { JoinExcursion } from "@/features/join-excursion";
import { getCurrentSchedules } from "@/shared/lib";
import { useMemo } from "react";
import { selectIsOpenFromMobileApp } from "@/entities/session";
import { useAppSelector } from "@/shared/model/hooks";

export const ExcursionInfo: React.FC = () => {
  const { id } = useParams();
  const isOpenFromMobileApp = useAppSelector(selectIsOpenFromMobileApp);

  const { excursionData, isLoading, images, isFetching } =
    useGetExcursionByIdQuery(
      { id: Number(id) },
      {
        selectFromResult: (res) => ({
          excursionData: res?.data?.data,
          images: res?.data?.data?.images?.map(
            (image: TResImage): TImage => ({
              id: image?.id,
              url:
                image?.formats?.medium?.url ||
                image?.formats?.small?.url ||
                image?.formats?.thumbnail?.url,
            })
          ),
          ...res,
        }),
        skip: !id,
      }
    );

  const currentSchedule = useMemo(() => {
    if (excursionData && excursionData.excursion_schedules) {
      return getCurrentSchedules(excursionData.excursion_schedules);
    }
  }, [excursionData]);

  const guides = useMemo(() => {
    if (currentSchedule) {
      const guides = currentSchedule.map(({ guide }) => guide);

      const uniqueArray: TGuide[] = Object.values(
        guides.reduce((acc: { [key: number]: TGuide }, item: TGuide) => {
          acc[item.id] = item;
          return acc;
        }, {})
      );

      return uniqueArray;
    }
  }, [currentSchedule]);

  if (isLoading || isFetching)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading size="lg" />
      </div>
    );

  return (
    <div>
      {!isOpenFromMobileApp && <BreadCrumbs />}
      {excursionData && (
        <ExcursionDetails
          images={images}
          excursionData={excursionData}
          AdditionalDetails={
            <>
              Гиды:{" "}
              {guides &&
                guides.map((guide, index) => (
                  <>
                    <Link
                      target="_blank"
                      className="link"
                      to={`/guides/${guide.id}`}
                    >
                      {guide.name}
                    </Link>
                    {index < guides.length - 1 && ", "}
                  </>
                ))}
            </>
          }
        >
          {currentSchedule?.length ? (
            <JoinExcursion
              excursionData={excursionData}
              btnText={"Стоимость: " + excursionData?.price + " руб"}
            />
          ) : (
            <button className="w-full btn text-lg font-[400]" disabled>
              Запись пока не доступна
            </button>
          )}
        </ExcursionDetails>
      )}
    </div>
  );
};
