import { selectIsAuthorized } from "@/entities/session";
import { useTickets } from "@/entities/user";
import { useAppSelector } from "@/shared/model/hooks";
import { Link } from "react-router-dom";

interface IProps {
  eventId: number;
  className?: string;
  children?: React.ReactNode;
}

export const AlreadyTicket: React.FC<IProps> = ({
  eventId,
  className,
  children,
}) => {
  const { checkingIsMyTicket } = useTickets();
  const isAuth = useAppSelector(selectIsAuthorized);
  const isAlreadyMyTicket = checkingIsMyTicket(eventId);

  if (!isAuth || !isAlreadyMyTicket) return <></>;

  if (children) return <div className={className}>{children}</div>;

  return (
    <div className={`alert bg-success ${className}`}>
      <p>
        Вы уже зарегистрированы на это событие!{" "}
        <Link to={"/profile/tickets"} className="link">
          Перейдите в профиль
        </Link>{" "}
        чтобы увидеть все свои билеты.
      </p>
    </div>
  );
};
