import { combineReducers } from "@reduxjs/toolkit";
import { sessionSlice } from "@/entities/session";
import { userSlice } from "@/entities/user/model/slice";
import { baseApi } from "@/shared/api";
import { routeSlice } from "@/entities/route";

export const rootReducer = combineReducers({
  [sessionSlice.name]: sessionSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [routeSlice.name]: routeSlice.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
});
