import React from "react";
import { Footer } from "./footer";
import { Header } from "./header";
import { MobileNavigation } from "./mobile-navigation";
import { useAppSelector } from "@/shared/model/hooks";
import { selectIsOpenFromMobileApp } from "@/entities/session";

interface IProps {
  children: React.ReactNode;
  Account: React.ReactNode;
}

export const MainLayout: React.FC<IProps> = ({ children, Account }) => {
  const isOpenFromMobileApp = useAppSelector(selectIsOpenFromMobileApp);

  return (
    <div className="w-full min-h-[100vh] flex flex-col">
      {!isOpenFromMobileApp && <Header Account={Account} />}
      <main
        className={`relative flex flex-col flex-1 neutralize-mobile-menu ${
          !isOpenFromMobileApp ? "main-padding-content" : ""
        } min-h-[90vh] lg:min-h-0`}
      >
        {children}
      </main>
      {!isOpenFromMobileApp && <MobileNavigation Account={Account} />}
      <Footer />
    </div>
  );
};
