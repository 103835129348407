import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import InputMask from "react-input-mask-format";
import { clearPhone } from "@/shared/lib";
import { useAppDispatch } from "@/shared/model/hooks";
import { changePhoneUser } from "@/entities/user";

import { useRegistrationMutation } from "@/entities/session/api/session-api";

interface IFormValues {
  phone: string;
  confirmPrivacyPolicy: boolean;
  useConditionsService: boolean;
}

export const RegistrationForm: React.FC = () => {
  const [error, setError] = useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [registrationFetch] = useRegistrationMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<IFormValues>();

  async function onSubmit({
    phone,
    confirmPrivacyPolicy,
    useConditionsService,
  }: IFormValues) {
    if (phone && confirmPrivacyPolicy && useConditionsService) {
      const cleanedPhoneNumber = clearPhone(phone);
      try {
        await registrationFetch({ phone: cleanedPhoneNumber }).unwrap();
        dispatch(changePhoneUser(cleanedPhoneNumber));
        navigate("/auth/registration-confirm");
      } catch (error) {
        if (error && typeof error === "string") setError(error);
      }
    }
  }

  return (
    <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
      {errors.phone && (
        <label className="text-error">{errors.phone.message}</label>
      )}
      <label className="">Данные для входа</label>
      <Controller
        name="phone"
        control={control}
        defaultValue=""
        rules={{
          required: "Введите телефон",
          validate: (value) => {
            if (value.includes("_")) return "Введите номер телефона";
          },
        }}
        render={({ field }) => (
          <InputMask
            {...field}
            className={`input input-bordered ${
              errors.phone ? "input-error" : ""
            }`}
            mask="+7 (999) 999-99-99"
            placeholder="Телефон"
          />
        )}
      />

      {errors.confirmPrivacyPolicy && (
        <label className="text-error">
          {errors.confirmPrivacyPolicy.message}
        </label>
      )}

      <div className="form-control">
        <label className="cursor-pointer label">
          <span className="label-text">
            Согласен с{" "}
            <Link target="_blank" to={"/policy"} className="link">
              политикой конфиденциальности
            </Link>
          </span>
          <input
            type="checkbox"
            {...register("confirmPrivacyPolicy", {
              required:
                "Необходимо подтвердить согласие с политикой конфиденциальности",
            })}
            className="checkbox checkbox-primary"
          />
        </label>
      </div>

      {errors.useConditionsService && (
        <label className="text-error">
          {errors.useConditionsService.message}
        </label>
      )}

      <div className="form-control">
        <label className="cursor-pointer label">
          <span className="label-text">
            Согласен с{" "}
            <Link target="_blank" to="/use-conditions" className="link">
              условиями использования
            </Link>
          </span>
          <input
            type="checkbox"
            {...register("useConditionsService", {
              required:
                "Необходимо подтвердить согласие с условиями использования",
            })}
            className="checkbox checkbox-primary"
          />
        </label>
      </div>

      {error && <label className="text-error text-center">{error}</label>}

      <button type="submit" className="submit-button" disabled={isSubmitting}>
        {isSubmitting ? (
          <span className="loading loading-spinner loading-sm"></span>
        ) : (
          "Регистрация"
        )}
      </button>
    </form>
  );
};
