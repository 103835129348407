import { Link, useParams } from "react-router-dom";
import {
  PlaceDetails,
  useGetPlacesByIdQuery,
  useGetPlacesBySlugQuery,
} from "@/entities/places";
import { FavoriteButton } from "@/features/favorite-place";
import { ToggleTripPlacesButton } from "@/features/trip-place";
import { useAppSelector } from "@/shared/model/hooks";
import {
  selectIsAuthorized,
  selectIsOpenFromMobileApp,
} from "@/entities/session";
import { ModalForm } from "@/features/application-hotel";
import { ModalLogin } from "@/features/auth";

export const PlaceInfo: React.FC = () => {
  const { id, slug } = useParams();
  const isAuth = useAppSelector(selectIsAuthorized);
  const isOpenFromMobileApp = useAppSelector(selectIsOpenFromMobileApp);

  const { placeDataById } = useGetPlacesByIdQuery(
    { id: Number(id) },
    {
      selectFromResult: (res) => ({
        placeDataById: res?.data?.data,
      }),
      skip: !id || !!slug,
    }
  );

  const { placeDataBySlug } = useGetPlacesBySlugQuery(
    { slug: String(slug) },
    {
      selectFromResult: (res) => ({
        placeDataBySlug: res?.data?.data,
      }),
      skip: !slug || !!id,
    }
  );

  const placeData = placeDataById || placeDataBySlug;

  return (
    <div>
      {!isOpenFromMobileApp && (
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <Link to={"/home#plates"}>Главная</Link>
            </li>
            {placeData && (
              <li>
                <Link to={"/places/list/" + placeData.category.slug}>
                  {placeData.category.title}
                </Link>
              </li>
            )}
          </ul>
        </div>
      )}
      {placeData && (
        <PlaceDetails
          FavoriteButton={
            isAuth ? (
              <FavoriteButton
                placeId={placeData?.id}
                size="sm"
                className="text-xl"
              />
            ) : (
              <ModalLogin subtitle="Чтобы добавлять места в избранное, необходимо авторизоваться">
                <FavoriteButton
                  placeId={placeData?.id}
                  size="sm"
                  className="text-xl"
                />
              </ModalLogin>
            )
          }
          placeData={placeData}
        >
          {isAuth && <ToggleTripPlacesButton placeId={placeData.id} />}
          {placeData.category.slug === "oteli" && (
            <ModalForm id={Number(id)} price={placeData?.average_price} />
          )}
        </PlaceDetails>
      )}
    </div>
  );
};
