interface IProps {
  children: React.ReactNode;
  align?: "end" | "start" | "center";
  className?: string;
}

export const Title: React.FC<IProps> = ({
  children,
  align = "center",
  className = "",
}) => {
  return (
    <h2
      className={`uppercase text-primary text-${align} kalmyk-font text-xl md:text-2xl mb-4 md:mb-7 ${className}`}
    >
      {children}
    </h2>
  );
};
